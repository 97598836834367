import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Card, Collapse } from "antd";

const { Panel } = Collapse;

function Help({ onClose }: any) {
  return (
    <div className="absolute bg-slate-100 z-10 w-full h-screen">
      <Card
        size="small"
        title="Ajuda"
        headStyle={{ color: "#73879C" }}
        extra={
          <Button
            shape="circle"
            type="link"
            onClick={() => onClose(false)}
            icon={<CloseCircleOutlined />}
          />
        }
      >
        <Collapse accordion className="Panel">
          <Panel header="Login" key="1">
            <p>
              O sistema de login da aplicação é simples, basta digitar o e-mail
              e senha cadastrados.
              <br />
              <br />O acesso é dado pelo administrador, dentro da parte de
              gerenciamento de usuários, caso esquecer sua senha, você mesmo
              poderá fazer um reset usando o email cadastrado.
            </p>
          </Panel>
          <Panel header="Menu" key="2">
            <p>
              Dá acesso a ações do sistema, contendo todos os módulos da
              aplicação, também as funções de gerenciamento de perfil,
              administrar usuários (para administradores) e SAIR
            </p>
          </Panel>
          <Panel header="Checklist" key="3">
            <p>
              O módulo de Checklist foi desenvolvido para que usuários possam
              controlar estado dos tickets criados a partir das vendas.
              <br />
              Lembrando que o sistema está conectado com o ERP Tático, o qual no
              momento da venda, cria um ticket de Checklist novo e o mesmo
              aparecerá na primeira coluna. A partir deste momento todo o
              processo de cada ticket é gerenciado pela aplicação.
            </p>
            <b>Regras:</b>
            <p>
              No sistema existem algumas regras para passar o ticket de um
              estado para o outro:
            </p>
            <p>
              <ul>
                <li>
                  Quando o ticket é criado, a única maneira de passar para o
                  próximo estado é efetuando o agendamento. No próprio botão de
                  ação chamado <b>Agendamento</b>.
                </li>
                <li>
                  Assim que efetuado o agendamento: Criamos o evento no
                  calendário, criamos um ticket de Medição e passamos o ticket
                  para o próximo estado.
                </li>
                <li>
                  A partir deste momento, um usuário do Checklist pode mover o
                  ticket para o próximo estado de forma manual ou quando o
                  ticket de Medição (o qual ficará relacionado com o ticket de
                  checklist) for finalizado, o sistema automaticamente passará o
                  ticket de Checklist para o próximo estado.
                </li>
                <li>
                  A partir desde estado, não existe nenhuma outra regra, o
                  próprio usuário deverá fazer as mudanças de estado de forma
                  manual.
                </li>
              </ul>
            </p>
            <p>
              Lembrando que, a partir do momento que um administrador do sistema
              fizer qualquer modificação de um ticket de Checklist/Medição fora
              do fluxo, esta ação elimina todas as regras acima.
            </p>
            <b>
              ** Todos os módulos de ticket tem informações como descrição,
              histórico, anexos e agendamentos.
            </b>
          </Panel>
          <Panel header="Medição" key="4">
            <p>
              O módulo de Medição foi desenvolvido para que usuários possam
              controlar estado dos tickets criados a partir do ticket de
              Checklist.
              <br />
              Quando é efetuado um agendamento no ticket de Checklist, o sistema
              automaticamente cria o ticket de Medição e adiciona na primeira
              coluna.
            </p>
            <b>Regras:</b>
            <p>
              No sistema existem algumas regras para passar o ticket de um
              estado para o outro:
            </p>
            <p>
              <ul>
                <li>
                  O usuário deverá associar o ticket de Medição para um{" "}
                  <b>Responsável</b>. Este será o técnico de campo que irá realizar a
                  medição.
                </li>
                <li>
                  Neste momento o <b>Responsável</b> receberá este ticket no
                  aplicativo, onde ele terá a possibilidade de tirar fotos e
                  videos. Ao fazer o upload das fotos/videos, o técnico poderá
                  editar o nome do arquivo, assim podendo colocar o nome
                  customizado para melhor entendimento.
                </li>
                <li>
                  Assim que o <b>Responsável</b> finalizar o ticket do
                  aplicativo, o sistema automaticamente passará o ticket para o
                  próximo estado.
                </li>
                <li>
                  Neste estado, a pessoa responsável pelo ticket, poderá aprovar
                  ou reprovar o mesmo. Quando aprovado, o ticket passará para o
                  próximo estado. Quando reprovado, o ticket volta para o{" "}
                  <b>Responsável</b> e o mesmo reaparecerá no aplicativo.
                </li>
              </ul>
            </p>
            <p>
              Lembrando que, a partir do momento que um administrador do sistema
              fizer qualquer modificação de um ticket de Checklist/Medição fora
              do fluxo, esta ação elimina todas as regras acima.
            </p>
            <b>
              ** Todos os módulos de ticket tem informações como descrição,
              histórico, anexos e agendamentos.
            </b>
          </Panel>
          <Panel header="Aplicativo" key="5">
            <p>
              Foi criado um aplicativo para os Medidores. E o download deste
              aplicativo pode ser feito para neste{" "}
              <a href={process.env.REACT_APP_APP_LINK}>link</a>.
              <br />
              Um administrador do sistema pode criar usuários para o uso do
              aplicativo, basta selecionar a permissão de Usuário na sessão de
              Aplicativo.
            </p>
            <b>Regras:</b>
            <p>No aplicativo só aparecerá os tickets:</p>
            <p>
              <ul>
                <li>
                  Os tickets de medição que estão na coluna Aguardando Medição;
                </li>
                <li>
                  Somente os tickets que estão associados ao usuário (o usuário
                  deve ser o Responsável do ticket);
                </li>
                <li>
                  Dentro do aplicativo, o usuário poderá visualizar informações
                  do ticket, efetuar upload de imagens e videos. Podem ser
                  tirados diretamente da camera ou poderá anexar fotos já
                  tiradas quais estão salvos no aparelho.
                </li>
                <li>
                  O aplicativo poderá funcionar de forma offline, exceto a
                  função de login, que obrigatoriamente necessita de conexão com
                  a internet. Após isso, o usuário poderá entrar nos tickets,
                  tirar fotos e anexar arquivos que estes dados serão salvos no
                  equipamento do usuário, até que tenha acesso a internet
                  novamente.
                </li>
                <li>
                  A atualização é feita de forma automática, porém o usuário
                  terá a opção de sincronizar de forma manual a qualquer momento
                  clicando no botão Atualizar.
                </li>
                <li>
                  Ao anexar videos ou fotos no ticket, o usuário poderá dar um
                  nome customizado a cada arquivo, assim podendo apontar de qual
                  espaço está sendo tirada a foto. Esta ação não é obrigatória,
                  desta forma se o usuário não digitar um nome, será exibido o
                  nome aleatório do arquivo que foi salvo no sistema.
                </li>
              </ul>
            </p>
            <p>
              Após a medição concluída, o ticket irá para a próxima coluna no
              módulo de medição para análise e sairá da lista do usuário do
              aplicativo. Caso o ticket for reprovado, ele voltará à lista do
              usuário com a cor vermelha, assim sinalizando o usuário que o
              ticket foi reprovado.
            </p>
          </Panel>
          <Panel header="Calendário " key="6">
            <p>
              No item verificar disponibilidades de pessoal e horários para
              agendamento da medição; <br />
              Pode-se verificar a agenda individualmente ( filtro ) selecionando
              cada elemento por sua cor;
              <br />
              Desmarcando filtro ( botão ) pode-se ter a visão geral
            </p>
          </Panel>
          <Panel header="Configurações" key="7">
            <p>Edita-se o perfil do usuário para acesso ao sistema</p>
          </Panel>
        </Collapse>
      </Card>
    </div>
  );
}

export default Help;
