import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { actions } from "../../authentication/services/redux/actions";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ForgotPassword } from "./ForgotPassword";

const LoginForm = () => {
  const location = useLocation()
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const state = useSelector<AuthenticationState, AuthenticationState>(
    (state) => state
  );
  
  const handleOpenPopup = () => {
    setShowForgotPassword(true);
  }

  const handleClosePopup = () => { 
    setShowForgotPassword(false);
  }
  const dispatch = useDispatch();
    useEffect(() => {
      dispatch({ type: actions.LOGOUT });
    }, [dispatch]);
  const onFinish = (values: any) => {
    dispatch({ type: actions.LOGIN_STARTED, payload: values });
  };

  if (state.isLoggedIn) {
    return <Navigate to={location.state.prevPath ? location.state.prevPath :  "/"} replace />;
  }

  return (
    <>
      <ForgotPassword isOpen={showForgotPassword} onClose={handleClosePopup} />
      <Form name="normal_login" onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Insira seu nome de usuário!",
            },
          ]}
        >
          <Input
            className="h-10"
            prefix={<UserOutlined />}
            placeholder="Usuário"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Insira sua senha",
            },
          ]}
        >
          <Input
            className="h-10"
            prefix={<LockOutlined />}
            type="password"
            placeholder="Senha"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item className="flex justify-center">
            <Button type="link" onClick={handleOpenPopup}> Esqueceu sua senha? </Button>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="h-10 w-full bg-cyan-900 font-semibold"
            loading={state.loading}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default LoginForm;
