import { createContext, useState } from "react";

export const CalendarAppointmentContext = createContext({});

export const CalendarAppointmentProvider = ({ children }: any) => {
  const [popIsOpen, setPopIsOpen] = useState(false);

  const onPopClose = () => setPopIsOpen(false);
  const onPopOpen = () => setPopIsOpen(true);

  return (
    <CalendarAppointmentContext.Provider
      value={{ onPopClose, onPopOpen, popIsOpen }}
    >
      {children}
    </CalendarAppointmentContext.Provider>
  );
};

export const CalendarAppointmentConsumer = ({ children }: any) => {
  return (
    <CalendarAppointmentContext.Consumer>
      {children}
    </CalendarAppointmentContext.Consumer>
  );
};
