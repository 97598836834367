import { Popconfirm, Tabs, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../../../interfaces/users";
import { useQuery } from "react-query";
import { api } from "../../../../../config";
import { useCallback, useContext, useMemo, useState } from "react";
import Wrapper from "./Wrapper";
import { TicketContext } from "../../../../Shared/contexts/TicketContext";
import { toast } from "react-toastify";

function Questionnaire() {
  const { token } = useSelector((state: AuthenticationState) => state);
  const { ticket } = useContext(TicketContext);
  const { data, isFetching, refetch } = useQuery(
    "questionnaire-templates",
    () => api(token).get("/questionnaire-templates")
  );
  const [accessorKey, setAccessorKey] = useState<string | undefined>(undefined);
  const { data: extraQuestionnairesData, refetch: extraQuestionnairesRefetch } =
    useQuery("extraQuestionnaires", () =>
      api(token).get(`/questionnaires/ticket/${ticket.id}`)
    );

  const handleCreateNewQuestionnaire = useCallback(
    async (questionnaireTemplateId: number, questionnaireName: string) => {
      try {
        await api(token).post("/questionnaires", {
          ticketId: ticket.id,
          questionnaireTemplateId: questionnaireTemplateId,
          name: `${questionnaireName} - ${new Date()
            .getTime()
            .toString()
            .substring(10)}`,
        });
        extraQuestionnairesRefetch();
      } catch (error) {
        toast.error("Ocorreu um erro ao criar questionário");
      }
    },
    [extraQuestionnairesRefetch, ticket.id, token]
  );

  const questionnaires = useMemo(
    () =>
      data?.map(({ questionnaireName, id }: any) => ({
        label: (
          <div className="w-full border flex justify-between">
            {questionnaireName}
            <Tooltip title="Duplicar Questionário">
              <button
                onClick={() =>
                  handleCreateNewQuestionnaire(id, questionnaireName)
                }
                className="border ml-2 shadow-md cursor-pointer hover:bg-blue-200 z-50 border-blue-400 rounded-xl bg-transparent"
              >
                +
              </button>
            </Tooltip>
          </div>
        ),
        key: id,
        children: <Wrapper key={id} questionnaireTemplateId={id} />,
      })),
    [data, handleCreateNewQuestionnaire]
  );

  const handleDeleteQuestionnaire = useCallback(
    async (id: number) => {
      try {
        await api(token).delete(`/questionnaires/${id}`);
        extraQuestionnairesRefetch();
      } catch (error) {
        toast.error("Ocorreu um erro ao deletar questionário");
      }
    },
    [extraQuestionnairesRefetch, token]
  );

  const extraQuestionnaires = useMemo(
    () =>
      extraQuestionnairesData?.map((questionnaire: any) => ({
        label: (
          <div className="w-full border flex justify-between">
            {questionnaire.name}
            <Popconfirm
              title="Apagar Questionário?"
              onConfirm={async () =>
                await handleDeleteQuestionnaire(questionnaire.id)
              }
            >
              <button className="border ml-2 shadow-md cursor-pointer hover:bg-blue-200 z-50 border-blue-400 rounded-xl bg-transparent">
                -
              </button>
            </Popconfirm>
          </div>
        ),
        key: `${questionnaire.id}-${questionnaire.questionnaireTemplateId}`,
        children: (
          <Wrapper
            extra
            questionnaireId={questionnaire.id}
            key={`${questionnaire.id}-${questionnaire.questionnaireTemplateId}`}
            questionnaireTemplateId={questionnaire.questionnaireTemplateId}
          />
        ),
      })) ?? [],
    [extraQuestionnairesData, handleDeleteQuestionnaire]
  );

  return (
    <>
      {!isFetching && (
        <Tabs
          className="mx-2 max-h-[65vh]"
          tabPosition="left"
          activeKey={accessorKey}
          onChange={(e) => {
            refetch();
            setAccessorKey(e);
          }}
          items={[...questionnaires, ...extraQuestionnaires]}
        />
      )}
    </>
  );
}

export default Questionnaire;
