import { useEffect, useMemo, useState } from "react";
import { KanbanBoard } from "../../../Kanban/Kanban";
import { TicketModal } from "../../../Checklist/TicketModal/TicketModal";
import TicketModalMeasuring from "../../../../modules/Measuring/TicketModal";
import { useSearchParams } from "react-router-dom";

interface BoardInterfaceProps {
  title: string;
  type: "measuring" | "checklist";
}

export const Board = ({ title, type }: BoardInterfaceProps) => {
  const searchParams = useSearchParams();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [ticketId, setTicketId] = useState<number>();

  const showHideModal = (id?: number) => {
    setShowTicketModal(!showTicketModal);
    setTicketId(id);
  };

  const queryId = useMemo(() => searchParams[0].get("id"), [searchParams]);

  useEffect(() => {
    if (queryId) {
      setShowTicketModal(true);
      setTicketId(Number(queryId));
    }
  }, [queryId]);

  return (
    <>
      <h1>{title}</h1>
      {ticketId && type === "checklist" && (
        <TicketModal
          showTicketModal={showTicketModal}
          setShowTicketModal={setShowTicketModal}
          ticketId={ticketId}
        />
      )}
      {ticketId && type === "measuring" && (
        <TicketModalMeasuring
          showTicketModal={showTicketModal}
          setShowTicketModal={setShowTicketModal}
          ticketId={ticketId}
        />
      )}
      <KanbanBoard type={type} onClick={showHideModal} />
    </>
  );
};
