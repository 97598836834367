//@ts-ignore
import Board from "@asseinfo/react-kanban";
import { useQuery } from "react-query";
import "./styles.css";

import { formatCards } from "../../utils";
import { api } from "../../config";

import { Button, Skeleton } from "antd";
import "./styles.css";

import dbData from "../../backup.json";
import { SearchBar } from "../SearchBar/SearchBar";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { useEffect, useState } from "react";
import { updateDatabaseTickets } from "../../utils/helpers/ticketHelpers";
import AddTicketModal from "./AddTicketModal";
import { ImportTicketModal } from "./ImportTIcketModal";

interface KanbanBoardProps {
  type: "checklist" | "measuring";
  onClick?: (id?: number) => void;
}

export const KanbanBoard = ({ type, onClick }: KanbanBoardProps) => {
  const { token } = useSelector((state: AuthenticationState) => state);
  const [importIsOpen, setImportIsOpen] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const { isLoading, data, isError, refetch } = useQuery(
    [`tickets/${type}/board`],
    () => api(token).get(`tickets/${type}/board`)
  );

  useEffect(() => {
    const updateTickets = async () => {
      setMainLoading(true);
      await updateDatabaseTickets(token, type);
      setMainLoading(false);
      refetch();
    };
    updateTickets();
  }, [refetch, token, type]);

  const backupData: any = dbData;

  if (isLoading) return <Skeleton active />;

  const renderOptions = (data: any) => {
    let onlyCards: any = data.map((item: any) => item.cards);
    return onlyCards.flat().map((option: any) => {
      return {
        id: String(option.id),
        value: option.id,
        title: option.title,
        label: (
          <div key={option.id} className="flex flex-col">
            <span className="text-blue-600">{option.title}</span>
            <small>
              <b>ID Simp:</b> {option.id ?? "(Não informada)"}
            </small>
            <small>
              <b>ID Venda:</b>
              {option.saleId ? option.saleId : "(Não informada)"}
            </small>
            <small>
              <b>ID Ticket:</b>
              {option.externalId ? option.externalId : "(Não informado)"}
            </small>
          </div>
        ),
        externalId: String(option.externalId),
        saleId: String(option.saleId),
      };
    });
  };

  const handleClick = (value: any, option: any) => {
    onClick?.(option.id);
  };

  return (
    <>
      <ImportTicketModal
        isOpen={importIsOpen}
        onClose={() => setImportIsOpen(false)}
        type={type}
        refetch={refetch}
      />
      <div className="w-full relative h-9">
        <div className="absolute left-0">
          <SearchBar
            placeholder="Buscar ticket..."
            options={renderOptions(isError ? backupData[type] : data)}
            onChangeFunction={handleClick}
          />
          {type === "measuring" && <AddTicketModal refetch={refetch} />}
        </div>
        <div className="absolute right-0">
          <Button
            onClick={() => setImportIsOpen(true)}
            className="font-bold"
            type="primary"
          >
            Importar Ticket
          </Button>
        </div>
      </div>

      {mainLoading ? (
        <Skeleton active />
      ) : (
        <Board disableCardDrag disableColumnDrag>
          {formatCards(type, isError ? backupData[type] : data, onClick)}
        </Board>
      )}
    </>
  );
};
