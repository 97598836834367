import { Button, Space } from "antd";

interface FormFooterProps {
    onClear: () => void;
    onSubmit: () => Promise<void>;
    loading: boolean;
}

export const FormFooter = ({ onClear, onSubmit, loading }: FormFooterProps) => {
  return (
    <Space className="mt-4 flex w-full justify-end">
      <Button onClick={onClear} danger>
        Limpar
      </Button>
      <div className="space-x-2"></div>
      <Button loading={loading} onClick={onSubmit} type="primary">
        Enviar
      </Button>
    </Space>
  );
};