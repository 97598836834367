import { Tooltip } from "antd";

export const EventContent = (eventInfo: any) => {
  const { hasPictures } = eventInfo.event.extendedProps;
  return (
    <Tooltip
      color="white"
      title={
        <div>
          {eventInfo.event.extendedProps.notes &&
          /<\/?[a-z][\s\S]*>/i.test(eventInfo.event.extendedProps.notes) ? (
            <div
              style={{ color: "black", background: "white" }}
              dangerouslySetInnerHTML={{
                __html: eventInfo.event.extendedProps.notes,
              }}
            />
          ) : (
            <div style={{ color: "black" }}>
              {eventInfo.event.extendedProps.notes}
            </div>
          )}
        </div>
      }
    >
      <div
        style={{
          maxWidth: "100%",
          borderRadius: 2,
          backgroundColor: eventInfo.backgroundColor,
          color: "white",
          textOverflow: "ellipsis",
          overflow: "hidden",
          overflowY: "auto",
          fontSize: "13px",
        }}
        className="overflow-clip print:text-black h-full print:h-fit mx-1"
      >
        <b style={{ margin: 5 }} className="print:text-black">
          {eventInfo.timeText} {eventInfo.event.title} -
        </b>

        <div className="w-full text-[10px] print:w-8/12 print:text-black">
          {eventInfo.event.extendedProps.description
            ? eventInfo.event.extendedProps.description
            : ""}
        </div>
        {hasPictures && <b style={{ float: "right", marginRight: 3 }}>o</b>}
      </div>
    </Tooltip>
  );
};
