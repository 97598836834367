import {
  WhatsAppOutlined,
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Descriptions, Card, Button } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { TicketContext } from "../../Shared/contexts/TicketContext";
import {
  checkListStatusTitles,
  updateClientAddress,
  updateClientInfo,
} from "../../../utils";
import Description from "./Description";
import { DescriptionFormInput } from "./DescriptionFormInput";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../interfaces/users";
import { DescriptionItem } from "./DescriptionItem";
import {
  formatPhoneNumber,
  isSharedComponentDisabled,
} from "../../../utils/helpers/userHelpers";
import { useQuery } from "react-query";
import { api } from "../../../config";
import { formatCurrency } from "../../../utils/helpers/ticketHelpers";

interface DetailInterfaceProps {
  ticketInfo?: any;
  type?: "measuring" | "checklist";
  disabled?: boolean;
}

const defaultClientInfoForm = {
  name: "",
  phoneNumber: "",
  alternativePhoneNumber: "",
};

const defaultForm = {
  street: "",
  add: "",
  neighbor: "",
  city: "",
  state: "",
  zipCode: "",
};

function Details({
  ticketInfo,
  type = "checklist",
  disabled = false,
}: DetailInterfaceProps) {
  const [editing, setEditing] = useState(false);
  const [editingClient, setEditingClient] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(defaultForm);
  const [clientFormData, setClientFormData] = useState(defaultClientInfoForm);
  const ticketContext = useContext(TicketContext) as any;
  const ticket = ticketInfo ? ticketInfo : ticketContext.ticket;
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const { refetch } = useQuery(
    [type === "checklist" ? "tickets" : "tickets/measuring"],
    () => api(token).get(`ticket/${type}/${ticket.id}`),
    { refetchOnMount: false }
  );

  const handleOnFormChange = ({ target }: any) => {
    const { id, value } = target;
    setFormData({ ...formData, [id]: value });
  };

  const setInitialFormData = useCallback(() => {
    setFormData({ ...ticket.client.address });
    setClientFormData({
      name: ticket.client.name,
      phoneNumber: formatPhoneNumber(ticket.client.phoneNumber),
      alternativePhoneNumber: formatPhoneNumber(
        ticket.client.alternativePhoneNumber
      ),
    });
    setEditing(false);
    setEditingClient(false);
  }, [ticket.client]);

  const handleAddressSave = async () => {
    setLoading(true);
    await updateClientAddress(token, ticket.id, formData, type);
    setEditing(false);
    setLoading(false);
    refetch();
  };

  const handleClientInfoSave = async () => {
    setLoading(true);
    await updateClientInfo(token, ticket.id, clientFormData, type);
    setEditing(false);
    setLoading(false);
    refetch();
  };

  useEffect(() => {
    setInitialFormData();
  }, [setInitialFormData]);

  const handleClientFormChange = (e: any) => {
    let { id, value } = e.target;
    setClientFormData({ ...clientFormData, [id]: value });
  };

  return (
    <div className="overflow-auto max-h-[60vh] mb-3">
      <Description type={type} disabled={disabled} ticketInfo={ticketInfo} />
      {!ticketInfo && (
        <>
          <Card
            className="mb-3"
            title="Venda"
            size="small"
            headStyle={{ color: "#1677FF" }}
          >
            <Descriptions size="small">
              <Descriptions.Item label="Fluxo">{"Checklist"}</Descriptions.Item>
              <Descriptions.Item label="Situação">
                {checkListStatusTitles[ticket.status]}
              </Descriptions.Item>
              <Descriptions.Item label="Vendedor">
                {ticket.seller ?? ticket.owner.fullName}
              </Descriptions.Item>
              <Descriptions.Item label="Código da Venda">
                {ticket.saleId ?? "Não vinculado"}
              </Descriptions.Item>
              <Descriptions.Item label="Código do Ticket">
                {ticket.externalId ?? "Não vinculado"}
              </Descriptions.Item>
              <Descriptions.Item label="Código Local">
                {ticket.id}
              </Descriptions.Item>
              <Descriptions.Item label="Valor Negociado">
                {formatCurrency(ticket.totalValue)}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </>
      )}

      <Card title="Cliente" size="small" headStyle={{ color: "#1677FF" }}>
        <div className="relative grid grid-cols-3 gap-4">
          <DescriptionFormInput
            onChange={handleClientFormChange}
            id="name"
            value={clientFormData.name}
            label="Nome:"
            editing={editingClient}
          />

          <DescriptionFormInput
            onChange={handleClientFormChange}
            value={formatPhoneNumber(clientFormData.phoneNumber)}
            id="phoneNumber"
            label="Contato:"
            editing={editingClient}
          >
            <a
              href={`https://api.whatsapp.com/send?phone=55${formatPhoneNumber(
                ticket.client.phoneNumber
              )}&text=Olá%2C%20somos%20da%20Aliança%20Móveis%20e%20estamos%20entrando%20em%20contato%20para`}
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppOutlined className="mr-1" />
              {formatPhoneNumber(ticket.client.phoneNumber)}
            </a>
          </DescriptionFormInput>

          <DescriptionFormInput
            onChange={handleClientFormChange}
            id="alternativePhoneNumber"
            value={formatPhoneNumber(clientFormData.alternativePhoneNumber)}
            label="Contato Alternativo:"
            editing={editingClient}
          >
            <a
              href={`https://api.whatsapp.com/send?phone=55${formatPhoneNumber(
                ticket.client.alternativePhoneNumber
              )}&text=Olá%2C%20somos%20da%20Aliança%20Móveis%20e%20estamos%20entrando%20em%20contato%20para`}
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppOutlined className="mr-1" />
              {formatPhoneNumber(ticket.client.alternativePhoneNumber)}
            </a>
          </DescriptionFormInput>
          <DescriptionItem label="CPF/CNPJ:">
            {ticket.client.taxId}
          </DescriptionItem>
          <DescriptionItem label="E-mail:">
            {ticket.client?.email}
          </DescriptionItem>

          <div className="w-full flex items-end justify-end">
            {editingClient ? (
              <div className="flex gap-2">
                <Button
                  className="mt-4"
                  type="link"
                  danger
                  onClick={setInitialFormData}
                  loading={loading}
                  icon={<CloseCircleOutlined />}
                />
                <Button
                  className="mt-4"
                  type="link"
                  onClick={handleClientInfoSave}
                  loading={loading}
                  icon={<SaveOutlined />}
                />
              </div>
            ) : (
              <Button
                disabled={isSharedComponentDisabled(type, user, disabled)}
                type="link"
                onClick={() => setEditingClient(true)}
                icon={<EditOutlined />}
              />
            )}
          </div>

          <DescriptionFormInput
            onChange={handleOnFormChange}
            id="street"
            value={formData.street}
            label="Endereço:"
            labelClass="mt-1"
            editing={editing}
          />

          <DescriptionFormInput
            onChange={handleOnFormChange}
            id="add"
            value={formData.add}
            label="Complemento:"
            labelClass="mt-1"
            editing={editing}
          />

          <DescriptionFormInput
            onChange={handleOnFormChange}
            id="neighbor"
            value={formData.neighbor}
            label="Bairro:"
            labelClass="mt-1"
            editing={editing}
          />

          <DescriptionFormInput
            onChange={handleOnFormChange}
            id="city"
            value={formData.city}
            label="Cidade:"
            editing={editing}
          />

          <DescriptionFormInput
            onChange={handleOnFormChange}
            id="state"
            value={formData.state}
            label="Estado:"
            editing={editing}
          />

          <DescriptionFormInput
            onChange={handleOnFormChange}
            id="zipCode"
            value={formData.zipCode}
            label="CEP:"
            editing={editing}
          />
        </div>
        <div className="w-full flex items-end justify-end">
          {editing ? (
            <div className="flex gap-2">
              <Button
                className="mt-4"
                type="link"
                danger
                onClick={setInitialFormData}
                loading={loading}
                icon={<CloseCircleOutlined />}
              />
              <Button
                className="mt-4"
                type="link"
                onClick={handleAddressSave}
                loading={loading}
                icon={<SaveOutlined />}
              />
            </div>
          ) : (
            <Button
              disabled={isSharedComponentDisabled(type, user, disabled)}
              type="link"
              onClick={() => setEditing(true)}
              icon={<EditOutlined />}
            />
          )}
        </div>
      </Card>
    </div>
  );
}

export default Details;
