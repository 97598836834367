import { applyMiddleware, createStore } from "@reduxjs/toolkit";
import { authMiddleware } from "./middlewares";
import { authReducer, initialState } from "./reducers/auth";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "users",
  storage,
};

export const persistedReducer = persistReducer(persistConfig, authReducer);

export const store = createStore(
  persistedReducer,
  //@ts-ignore
  initialState,
  applyMiddleware(authMiddleware)
);

export const persistor = persistStore(store);
