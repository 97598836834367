import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Popconfirm, Space } from "antd";
import { changeTicketStatus } from "../../../utils";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../interfaces/users";
import { api } from "../../../config";
import { useQueries } from "react-query";
import { useState } from "react";
import { hasMinimumPermissions } from "../../../utils/helpers/userHelpers";

function ButtonMore({ ticketId }: any) {
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const [{ data, refetch: ticketRefetch }, { refetch: boardRefetch }] =
    useQueries([
      {
        queryKey: "tickets",
        queryFn: () => api(token).get(`ticket/checklist/${ticketId}`),
        refetchOnMount: false,
      },
      {
        queryKey: "tickets/checklist/board",
        queryFn: () => api(token).get(`tickets/checklist/board`),
        refetchOnMount: false,
      },
    ]);

  const [loadings, setLoadings] = useState(false);

  const handleOnClick = async (status: number) => {
    setLoadings(true);
    await changeTicketStatus(status, ticketId, token, "checklist");
    // if (data.fieldReportId && status === 0) {
    //   await deleteFieldReport(token, data.fieldReportId);
    // }
    boardRefetch();
    ticketRefetch();
    setLoadings(false);
  };
  const items = [
    {
      key: "1",
      label: (
        <Popconfirm
          disabled={
            data.status === 1 || !hasMinimumPermissions("checklist", user)
          }
          onConfirm={async () => await handleOnClick(0)}
          title="Tem certeza de que deseja reiniciar esta venda?"
        >
          <Button
            type="text"
            disabled={
              data.status === 1 || !hasMinimumPermissions("checklist", user)
            }
          >
            Reiniciar Ticket
          </Button>
        </Popconfirm>
      ),
      onClick: (e: any) => e.preventDefault(),
    },
    {
      key: "2",
      label: (
        <Button
          disabled={!hasMinimumPermissions("checklist", user)}
          danger
          type="text"
        >
          Cliente em Obras
        </Button>
      ),
      danger: true,
      onClick: () =>
        hasMinimumPermissions("checklist", user) ? handleOnClick(4) : undefined,
    },
    {
      key: "3",
      label: (
        <Popconfirm
          zIndex={5000}
          disabled={!hasMinimumPermissions("checklist", user)}
          onConfirm={() =>
            hasMinimumPermissions("checklist", user)
              ? handleOnClick(5)
              : undefined
          }
          title="Tem certeza de que deseja cancelar este Ticket?"
        >
          <Button
            disabled={!hasMinimumPermissions("checklist", user)}
            danger
            type="text"
          >
            Cancelar Ticket
          </Button>
        </Popconfirm>
      ),
      danger: true,
      onClick: (e: any) => e?.preventDefault?.(),
    },
  ];
  return (
    <Space className="relative right-0 top-2">
      <Dropdown menu={{ items }} placement="bottomLeft">
        <Button size="small" shape="circle" loading={loadings}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    </Space>
  );
}
export default ButtonMore;
