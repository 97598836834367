import { useEffect, useState } from "react";
import { AuthenticationState } from "../../interfaces/users";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../../utils";
import { actions } from "../../authentication/services/redux/actions";
import { Card, Input } from "antd";
import { FormFooter } from "./FormFooter";
const defaultForm = {
  fullName: "",
  email: "",
  phoneNumber: "",
  taticoUsrId: "",
};

interface UserInformationFormProps {
  onFormChange?: ({ target }: any) => void;
  shouldDisplayFooter?: boolean;
  className?: string;
  controllerFormData?: any;
}

export const UserInformationForm = ({
  onFormChange,
  shouldDisplayFooter,
  className = "mr-4 w-1/2",
  controllerFormData,
}: UserInformationFormProps) => {
  const [formData, setFormData] = useState(defaultForm);
  const [loading, setLoading] = useState(false);
  const { user, token } = useSelector((state: AuthenticationState) => state);
  const dispatch = useDispatch();

  const formInitialState = () => {
    setFormData({
      ...formData,
      fullName: user?.fullName ?? "",
      email: user?.email ?? "",
      phoneNumber: user?.phoneNumber ?? "",
      taticoUsrId: user?.taticoUsrId ?? "",
    });
  };

  useEffect(() => {
    formInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleOnformChange = ({ target }: any) => {
    if (onFormChange) {
      return onFormChange({ target });
    }
    const { id, value } = target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await updateUserInfo(token, user?.id ?? 0, formData);
    dispatch({
      type: actions.UPDATE_USER,
      payload: { userId: user?.id },
    });
    setLoading(false);
  };

  const handleNumberOnChange = ({ target }: any) => {
    const { id, value } = target;

    handleOnformChange({
      target: {
        id,
        value: Number(value.replace(/\D+/g, "")),
      },
    });
  };

  return (
    <Card className={className} title="Informações de usuário">
      <p>Nome:</p>
      <Input
        value={controllerFormData?.fullName ?? formData.fullName}
        onChange={handleOnformChange}
        id="fullName"
      />
      <p>Email:</p>
      <Input
        value={controllerFormData?.email ?? formData.email}
        onChange={handleOnformChange}
        id="email"
      />
      <p>Celular:</p>
      <Input
        value={controllerFormData?.phoneNumber ?? formData.phoneNumber}
        onChange={handleOnformChange}
        id="phoneNumber"
      />
      <p>ID Usuário do Tatico:</p>
      <Input
        value={controllerFormData?.taticoUsrId ?? formData.taticoUsrId}
        onChange={handleNumberOnChange}
        id="taticoUsrId"
      />
      {shouldDisplayFooter && (
        <FormFooter
          loading={loading}
          onClear={formInitialState}
          onSubmit={handleSubmit}
        />
      )}
    </Card>
  );
};
