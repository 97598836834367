import { Select } from "antd";

interface PermissionsFormProps {
  onChange: (e: any) => void;
  formData: any;
}

export const PermissionsForm: React.FC<PermissionsFormProps> = ({
  onChange,
  formData,
}) => {
  const handleOnFormChange = (id: string, value: any) => {
    onChange({ target: { id, value } });
  };

  const options = [
    { label: "Nenhuma", value: "NONE" },
    { label: "Visualizar", value: "VIEW" },
    { label: "Usuário", value: "USER" },
    { label: "Administrador", value: "ADMIN" },
  ];

  return (
    <div>
      <p>Checklist</p>
      <Select
        id="checklist"
        onChange={(value) => handleOnFormChange("checklist", value)}
        value={formData.checklist}
        style={{ width: "100%" }}
        options={options}
      />
      <p>Medição</p>
      <Select
        id="measuring"
        onChange={(value) => handleOnFormChange("measuring", value)}
        value={formData.measuring}
        style={{ width: "100%" }}
        options={options}
      />
      <p>Calendários</p>
      <Select
        id="calendar"
        onChange={(value) => handleOnFormChange("calendar", value)}
        value={formData.calendar}
        style={{ width: "100%" }}
        options={options}
      />
      <p>Usuários</p>
      <Select
        id="users"
        onChange={(value) => handleOnFormChange("users", value)}
        value={formData.users}
        style={{ width: "100%" }}
        options={options}
      />
      <p>Applicativo Medição</p>
      <Select
        id="app"
        onChange={(value) => handleOnFormChange("app", value)}
        value={formData.app}
        style={{ width: "100%" }}
        options={options.filter(
          ({ value }) => value === "NONE" || value === "USER"
        )}
      />
      <p>Aplicativo Montagem</p>
      <Select
        id="assemble_app"
        onChange={(value) => handleOnFormChange("assemble_app", value)}
        value={formData.assemble_app || "NONE"}
        style={{ width: "100%" }}
        options={options.filter(
          ({ value }) => value === "NONE" || value === "USER"
        )}
      />
    </div>
  );
};
