import Attachments from "../../../Shared/Components/Attachments";
import History from "../../../Shared/Components/History";
import Details from "../../../Shared/Components/Details";
import MainInformation from "../MainInformation";
import ButtonMore from "../ButtonMore";

function TicketMeasure({ ticket, disabled, type = "measuring" }: any) {
  return (
    <>
      <div className="z-10 w-16 pl-40 h-0 justify-center left-3/4  grid relative">
        <ButtonMore disabled={disabled} ticketId={ticket.id} />
      </div>
      <MainInformation disabled={disabled} ticket={ticket} />
      <Details disabled={disabled} ticketInfo={ticket} type="measuring" />
      <Attachments disabled={disabled} ticketInfo={ticket} />
      <History ticketInfo={ticket} />
    </>
  );
}

export default TicketMeasure;
