import { Card, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { renderDateFormated, tagColors } from "../../../utils";
import { TicketContext } from "../../Shared/contexts/TicketContext";
import { useContext } from "react";

interface HistoryInterfaceProps {
  ticketInfo?: any;
}

interface DataType {
  key: string;
  user: string;
  description: string;
  type: string;
  date: string;
}

function History({ ticketInfo }: HistoryInterfaceProps) {
  const ticketContext = useContext(TicketContext) as any;
  const ticket = ticketInfo ? ticketInfo : ticketContext.ticket;

  const typeTags = [
    {
      text: "Sistema",
      value: "sistema",
    },
    {
      text: "Descrição",
      value: "descrição",
    },
    {
      text: "Status",
      value: "status",
    },
    {
      text: "Anexo",
      value: "anexo",
    },
    {
      text: "Ticket",
      value: "ticket",
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      width: 150,
      render: (date) => renderDateFormated(date),
      sorter: (a, b) => {
        const c: any = new Date(a.date);
        const d: any = new Date(b.date);
        return c - d;
      },
    },
    {
      title: "Usuário",
      dataIndex: "user",
      key: "user",
      width: 150,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Tipo",
      key: "type",
      dataIndex: "type",
      filters: typeTags,
      onFilter: (value: any, record) => record.type.indexOf(value) === 0,
      render: (type) => (
        <Tag color={tagColors[type]}>{type?.toUpperCase()}</Tag>
      ),
      width: 200,
    },
  ];

  const history: DataType[] = ticket.history.map((log: any) => ({
    key: log.referenceId,
    user: log.user?.fullName ?? "Sistema",
    description: log.actionDescription,
    type: log.referenceType.toLowerCase(),
    date: log.createdAt,
  }));

  return (
    <div className="pt-1">
      <Card
        title="Histórico"
        size="small"
        headStyle={{ color: " rgb(59 130 246)" }}
      >
        <Table
          className=" overflow-auto"
          size="small"
          columns={columns}
          dataSource={history}
          pagination={false}
          style={{ maxHeight: "25vh" }}
        />
      </Card>
    </div>
  );
}

export default History;
