import Schedules from "./Schedules";
import History from "../../../../Shared/Components/History";

function ScheduleHistory() {
  return (
    <div className="mx-2">
      <Schedules />
      <History />
    </div>
  );
}

export default ScheduleHistory;
