import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "./authentication/services/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import MainLayout from "./modules/MainLayout";

const App: React.FC = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MainLayout />
      </PersistGate>
    </ReduxProvider>
  );
};

export default App;
