import { toast } from "react-toastify";
import { api } from "./config";
import CardDescription from "./modules/Kanban/CardDescription";
import CardTitle from "./modules/Kanban/CardTitle";
import { sub } from "date-fns";

export const renderDateFormated = (
  date: string | Date,
  long?: boolean,
  hideShowTime?: boolean
) => {
  if (long) {
    return new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "full",
      timeStyle: "short",
    }).format(new Date(date));
  }
  return new Intl.DateTimeFormat("pt-BR", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: hideShowTime ? undefined : "2-digit",
    minute: hideShowTime ? undefined : "2-digit",
  }).format(new Date(date));
};

export const customGreetings = (name = "") => {
  const myDate = new Date();
  const hours = myDate.getHours();
  if (hours >= 12 && hours <= 18) return `Boa tarde ${name},`;
  if (hours >= 18 && hours <= 24) return `Boa noite ${name},`;
  return `Bom dia ${name},`;
};

export const changeTicketStatus = async (
  status: number,
  ticketId: number,
  token: string,
  type: "checklist" | "measuring"
) => {
  try {
    await api(token).put(`ticket/${type}/${ticketId}`, {
      status: status + 1,
    });
    toast.success("Ticket atualizado com sucesso");
  } catch (error: any) {
    console.error(error.message);
    toast.error("Ocorreu um erro ao alterar o status do ticket");
  }
};

export const measuringTicketReject = async (
  token: string,
  ticketId: number
) => {
  try {
    await api(token).put(`ticket/measuring/${ticketId}`, {
      status: 2,
      rejected: true,
    });
    toast.success("Ticket rejeitado com sucesso");
  } catch (error: any) {
    console.error(error.message);
    toast.error("Ocorreu um erro ao rejeitar o ticket");
  }
};

export const measuringTicketApprove = async (
  token: string,
  fieldReportId: number
) => {
  try {
    await api(token).put(`ticket/measuring/approve/${fieldReportId}`);
    toast.success("Ticket aprovado com sucesso");
  } catch (error: any) {
    console.error(error.message);
    toast.error("Ocorreu um erro ao aprovar o ticket");
  }
};

export const capitalizeWords = (text: string) => {
  if (!text) return "";
  const words = text?.split(" ");

  const capitalizedWords = words?.map((word) => {
    const capitalizedFirstLetter = word?.charAt(0)?.toUpperCase();
    const lowercaseRestOfWord = word?.slice(1)?.toLowerCase();
    return capitalizedFirstLetter + lowercaseRestOfWord;
  });

  return capitalizedWords?.join(" ");
};

export const formatCards = (type: string, cards: any, onClick?: any) => {
  return {
    columns: cards.map((column: any) => {
      return {
        ...column,
        cards: column.cards
          .filter(({ createdAt }: any) => {
            if (type === "checklist") {
              return new Date(createdAt) > sub(new Date(), { days: 900 });
            }
            return true;
          })
          .map((card: any) => {
            return {
              id: card.id,
              title: (
                <CardTitle
                  id={card.id}
                  title={card.title}
                  saleDate={card.saleDate}
                  onClick={onClick}
                />
              ),
              description: (
                <CardDescription
                  type={type}
                  client={card.client}
                  seller={card.seller}
                  scheduleDate={
                    card.startAt ??
                    card.scheduleDate ??
                    card.dueDate ??
                    undefined
                  }
                  neighbor={card.neighbor}
                  state={card.state}
                  city={card.city}
                  hasAttachments={card.hasAttachments ? true : false}
                />
              ),
            };
          }),
      };
    }),
  };
};

export const renderPercentage = (status: number) => {
  if (status === 1) return 25;
  if (status === 2) return 50;
  if (status === 3) return 75;
  if (status === 4) return 100;
  if (status === 5 || status === 6) return 85;
};

export const checkListStatusTitles: any = {
  1: "(SEC) Agendamento",
  2: "(LM) Medição",
  3: "(Proj) Aguardando Checklist",
  4: "(FIN) Checklist Concluído",
  5: "(SEC) Clientes Obras/Planta",
  6: "Ticket Cancelado",
};

export const measuringStatusTitles: any = {
  1: "Pendentes",
  2: "Medição",
  3: "Análise",
  4: "Aprovados",
  5: "Cancelados",
  6: "Em Obras",
};

export const TicketCategoriesMeasuring: any = {
  MEASURING_PENDING: "PENDENTES",
  MEASURING_WAITING_MEASURING: "AGUARDANDO MEDIÇÃO",
  MEASURING_WAITING_ANALYSIS: "ANÁLISE",
  MEASURING_APPROVED: "APROVADOS",
  MEASURING_CANCELLED: "CANCELADOS",
  MEASURING_WIP: "EM OBRAS",
};

export const TicketCategoriesChecklist: any = {
  CHECKLIST_WAITING_SCHEDULE: "(SEC) AGENDAMENTO",
  CHECKLIST_WAITING_MEASURING: "(LM) AGUARDANDO MEDIÇÃO",
  CHECKLIST_WAITING_CHECKLIST: "(PROJ) AGUARDANDO CHECKLIST",
  CHECKLIST_FINISHED: "(FIN) CHECKLIST CONCLUÍDO",
  CHECKLIST_NO_SHOW: "(SEC) CLIENTE EM OBRAS",
};

export const tagColors: any = {
  descrição: "geekblue",
  status: "green",
  agendamento: "magenta",
  sistema: "cyan",
  anexo: "orange",
  ticket: "yellow",
};

export const noRetry = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  retry: 1,
  retryDelay: 3000,
};

export const formatNumber = (number: string | undefined): string =>
  number ? number.replace(/[^\w\s]/gi, "") : "";

export const truncateString = (str: string, size: number) => {
  if (str.length <= size) {
    return str;
  }
  return str.slice(0, size) + "...";
};

export const getInitials = (name: string) => {
  const firstLetter = name[0];
  const secondLetter = name.split(" ")[1] ?? " ";
  return `${firstLetter} ${secondLetter[0]}`;
};

export const postQuestionnaireAnswers = async (token: string, payload: any) => {
  try {
    const answersSent = await api(token).post("questionnaire-answers", payload);
    toast.success("Questionário salvo com sucesso!");
    return answersSent;
  } catch (error) {
    toast.error("Ocorreu um erro ao salvar o formulário");
    return false;
  }
};

export const printPdf = async (token: string, payload: any) => {
  try {
    const pdf = await api(token).post(
      "questionnaire-answers/exportPdf",
      payload
    );
    toast.success("PDF gerado com sucesso!");
    return pdf;
  } catch (error) {
    toast.error("Ocorreu um erro ao gerar o PDF");
    throw new Error(
      "É possível que não haja nenhuma questão salva, salve o questionário e tente novamente."
    );
  }
};

export const sendPdfByEmail = async (token: string, payload: any) => {
  try {
    await api(token).post("/questionnaire-answers/sendByEmail", payload);
    toast.success("E-mail enviado com sucesso!");
  } catch (error) {
    toast.error("E-mail não foi enviado, tente novamente mais tarde");
  }
};

export const updateUserInfo = async (
  token: string,
  userId: number,
  payload: any
) => {
  try {
    if (payload.password === "") {
      delete payload.password;
    }
    delete payload.confirmPassword;
    const updatedUser = await api(token).put(`/users/${userId}`, payload);
    toast.success("Usuário atualizado com sucesso!");
    return updatedUser;
  } catch (error) {
    toast.error("Ocorreu um erro ao Atualizar o usuário");
  }
};

export const deleteUser = async (token: string, userId: number) => {
  try {
    await api(token).delete(`/users/${userId}`);
    toast.success("Usuário deletado com sucesso!");
  } catch (error) {
    toast.error("Ocorreu um erro ao deletar usuário");
    return false;
  }
};

export const updatePermissions = async (
  token: string,
  userId: number,
  payload: any
) => {
  try {
    const updated = await api(token).put(
      `/users/${userId}/permissions`,
      payload
    );
    toast.success("Permissões alteradas com sucesso!");
    return updated;
  } catch (error) {
    toast.error("Ocorreu um erro ao alterar as permissões");
    return false;
  }
};

export const createUser = async (token: string, payload: any) => {
  delete payload.confirmPassword;
  try {
    const updated = await api(token).post(`/users`, payload);
    toast.success("Usuário criado com sucesso");
    return updated;
  } catch (error) {
    toast.error("Ocorreu um erro ao criar o usuário");
    return false;
  }
};
export const updateClientAddress = async (
  token: string,
  ticketId: number,
  payload: any,
  type: "checklist" | "measuring"
) => {
  try {
    let updatedClient: any;
    if (type === "checklist") {
      updatedClient = await api(token).put(`ticket/checklist/${ticketId}`, {
        address: payload,
      });
    } else {
      updatedClient = await api(token).put(`ticket/measuring/${ticketId}`, {
        deliveryAddress: payload,
      });
    }
    toast.success("Endereço alterado com sucesso!");
    return updatedClient;
  } catch (error) {
    toast.error("Ocorreu um erro ao alterar o endereço");
    return false;
  }
};

export const updateClientInfo = async (
  token: string,
  ticketId: number,
  payload: any,
  type: "checklist" | "measuring"
) => {
  try {
    let updatedClient: any;
    updatedClient = await api(token).put(`ticket/${type}/${ticketId}`, {
      client: payload,
    });

    toast.success("Endereço alterado com sucesso!");
    return updatedClient;
  } catch (error) {
    toast.error("Ocorreu um erro ao alterar o endereço");
    return false;
  }
};

export const deleteFieldReport = async (
  token: string,
  fieldReportId: number
) => {
  try {
    await api(token).delete(`ticket/measuring/${fieldReportId}`);
  } catch (error) {
    toast.error("Ocorreu um erro ao deletar o ticket de medição atribuído");
  }
};
