import { Button, FormInstance, Table } from "antd";
import { renderDateFormated } from "../../utils";
import { Excel } from "antd-table-saveas-excel";
import { DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { toast } from "react-toastify";

interface ReportDataProps {
  key: string;
  title: string;
  contact: string;
  createdAt: string;
  endAt: string;
  status: string;
  owner: string;
  description: string;
}

interface TableDataProps {
  data: ReportDataProps[];
  searchForm: FormInstance;
  loading: boolean;
}

export const TableData = ({ data, searchForm, loading }: TableDataProps) => {
  const [exportLoading, setExportLoading] = useState(false);
  const columns: any = [
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Contato",
      dataIndex: "contact",
      key: "contact",
      sorter: (a: any, b: any) =>
        a.contact ? a.contact.localeCompare(b.contact) : "",
    },
    {
      title: "Inclusão",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: any) =>
        date ? renderDateFormated(date, false, true) : "",
    },
    {
      title: "Conclusão",
      dataIndex: "endAt",
      key: "endAt",
      sorter: (a: any, b: any) =>
        new Date(a.endAt).getTime() - new Date(b.endAt).getTime(),
      render: (date: any) =>
        date ? renderDateFormated(date, false, true) : "",
    },
    {
      title: "Situação",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) =>
        a.status ? a.status.localeCompare(b.status) : "",
    },
    {
      title: "Data Envio Medição",
      dataIndex: "sentToApp",
      key: "sentToApp",
      sorter: (a: any, b: any) => a.sentToApp > b.sentToApp,
      render: (date: string): string =>
        date ? renderDateFormated(date, false, false) : "",
    },
    {
      title: "Data Preenchimento",
      dataIndex: "filledAt",
      key: "filledAt",
      sorter: (a: any, b: any) => {
        return new Date(a.filledAt).getTime() - new Date(b.filledAt).getTime();
      },
      render: (date: string): string =>
        date ? renderDateFormated(date, false, false) : "",
    },
    {
      title: "Medidor",
      dataIndex: "owner",
      key: "owner",
      sorter: (a: any, b: any) =>
        a.owner ? a.owner.localeCompare(b.owner) : "",
    },

    {
      title: "Vendedor",
      dataIndex: "seller",
      key: "seller",
      sorter: (a: any, b: any) =>
        a.seller ? a.seller.localeCompare(b.seller) : "",
    },
  ];

  const handleExport = () => {
    setExportLoading(true);
    const excel = new Excel();
    excel
      .addSheet("Tickets")
      .addColumns([
        ...columns,
        {
          title: "Descrição",
          dataIndex: "description",
          key: "description",
        },
      ])
      .addDataSource(
        data.map((item) => ({
          ...item,
          description: item.description?.replace(/(<([^>]+)>)/gi, ""),
        })),
        {
          str2Percent: true,
        }
      )
      .saveAs("Tickets.xlsx");
    toast.success("Exportação concluída. Download iniciado");
    setExportLoading(false);
  };

  const handleRow = (record: any) => {
    return {
      onClick: (event: any) => {
        window.open(
          `/${
            searchForm.getFieldValue("type") === "measuring"
              ? "medicao"
              : "checklist"
          }?id=${record.key}`
        );
      },
    };
  };
  return (
    <div className="relative">
      {data.length > 0 && (
        <Button
          type="dashed"
          icon={<DownloadOutlined />}
          size="small"
          className="absolute z-20 -top-8 right-2"
          onClick={() => handleExport()}
          loading={exportLoading}
          disabled={exportLoading}
        >
          Exportar
        </Button>
      )}
      <Table
        loading={loading}
        onRow={handleRow}
        rowClassName={"cursor-pointer"}
        dataSource={loading ? [] : data}
        columns={
          searchForm.getFieldValue("type") === "checklist"
            ? columns.filter(
                (column: any) =>
                  !["filledAt", "sentToApp"].includes(column.key ?? "")
              )
            : columns.filter((column: any) => column.key !== 'seller')
        }
        className="z-10"
      />
    </div>
  );
};
