import { Button, Divider, Form, Popconfirm, Skeleton, Space } from "antd";
import Question from "./Question";
import Email from "./Email";
import { useQueries } from "react-query";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../../../interfaces/users";
import { api } from "../../../../../config";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { TicketContext } from "../../../../Shared/contexts/TicketContext";
import {
  postQuestionnaireAnswers,
  printPdf,
  sendPdfByEmail,
} from "../../../../../utils";
import { SaveButton } from "./SaveButton";
import { hasMinimumPermissions } from "../../../../../utils/helpers/userHelpers";
import { toast } from "react-toastify";

interface WrapperProps {
  questionnaireTemplateId: number;
  extra?: boolean;
  questionnaireId?: number;
}

const initialLoading = {
  save: false,
  print: false,
  email: false,
};

const Wrapper: FC<WrapperProps> = ({
  questionnaireTemplateId,
  extra = false,
  questionnaireId,
}) => {
  const { ticket } = useContext(TicketContext);
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const [edit, setEdit] = useState(false);
  const [destination, setDestination] = useState("");
  const [loading, setLoading] = useState(initialLoading);
  const [
    { refetch, isFetching, data },
    { isFetching: isFetchinAnswers, data: answersData },
    { refetch: ticketRefetch },
  ] = useQueries([
    {
      queryKey: "questionnaire",
      queryFn: () =>
        api(token).get(`/questionnaire-template/${questionnaireTemplateId}`),
    },
    {
      queryKey: "questionAnswers",
      queryFn: () => {
        if (extra) {
          return api(token).get(
            `/questionnaire-answers?questionnaireId=${questionnaireId}&ticketId=${ticket.id}`
          );
        } else {
          return api(token).get(
            `/questionnaire-answers?templateId=${questionnaireTemplateId}&ticketId=${ticket.id}`
          );
        }
      },
    },
    {
      queryKey: "tickets",
      queryFn: () => api(token).get(`ticket/checklist/${ticket.id}`),
      refetchOnMount: false,
    },
  ]);

  const [form] = Form.useForm();

  useEffect(() => {
    let formToUpdate = form.getFieldsValue();
    if (!answersData?.length && data?.questionnaireName === "Geral") {
      let answersToAdd = {};
      data.questions.forEach((question: any) => {
        answersToAdd = { ...answersToAdd, [question.id]: "Sim" };
      });
      formToUpdate = {
        ...formToUpdate,
        ...answersToAdd,
      };
    }

    answersData?.forEach?.((question: any) => {
      let answersToAdd = {};
      if (question.value.match(/@@ADD@@/)) {
        answersToAdd = {
          [question.questionId]: question.value.split(/@@ADD@@/)[0].trim(),
          [`ADD@@${question.questionId}`]: question.value
            .split(/@@ADD@@/)[1]
            .trim(),
        };
      } else {
        answersToAdd = { [question.questionId]: question.value };
      }

      formToUpdate = {
        ...formToUpdate,
        ...answersToAdd,
      };
    });
    form.setFieldsValue(formToUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersData, data]);

  const handlePrint = async () => {
    setLoading({ ...loading, print: true });
    try {
      const pdf = await printPdf(token, {
        questionnaireTemplateId: extra ? undefined : questionnaireTemplateId,
        ticketId: ticket.id,
        questionnaireId: extra ? questionnaireId : undefined,
      });
      setLoading({ ...loading, print: false });
      window.open(pdf.url);
      refetch();
      ticketRefetch();
    } catch (error: any) {
      toast.error(error.message);
      setLoading({ ...loading, print: false });
    }
  };

  const sendByEmail = async () => {
    setLoading({ ...loading, email: true });
    await sendPdfByEmail(token, {
      questionnaireTemplateId: extra ? undefined : questionnaireTemplateId,
      ticketId: ticket.id,
      questionnaireId: extra ? questionnaireId : undefined,
      destination,
    });
    refetch();
    ticketRefetch();
    setLoading({ ...loading, email: false });
    return true;
  };

  const onFinish = async (values: any) => {
    const formattedAnswers = Object.entries(values)
      .filter(([key, value]) => !key.match(/ADD@@/) && value)
      .map(([questionId, value]) => ({
        value,
        questionId: Number(questionId),
        ticketId: ticket.id,
        questionnaireTemplateId: Number(questionnaireTemplateId),
        questionnaireId: extra ? Number(questionnaireId) : undefined,
        valueType: "any",
      }));

    Object.entries(values).forEach(([key, value]) => {
      if (value === null) return;
      if (key.match(/ADD@@/) && value) {
        const formattedQuestionId = Number(key.replace(/ADD@@/, ""));
        const answerIndex = formattedAnswers.findIndex(
          (answer) => formattedQuestionId === answer?.questionId
        );

        formattedAnswers[answerIndex < 0 ? formattedQuestionId : answerIndex] =
          {
            ...(answerIndex < 0
              ? {
                  value,
                  questionId: Number(formattedQuestionId),
                  ticketId: ticket.id,
                  questionnaireTemplateId: Number(questionnaireTemplateId),
                  questionnaireId: extra ? Number(questionnaireId) : undefined,
                  valueType: "any",
                }
              : formattedAnswers[answerIndex]),
            value: `${
              formattedAnswers[answerIndex]?.value
                ? formattedAnswers[answerIndex]?.value
                : "Outra resposta"
            }@@ADD@@${value}`,
          };
      }
    });
    setLoading({ ...loading, save: true });
    await postQuestionnaireAnswers(
      token,
      formattedAnswers.filter((ans) => ans)
    );
    setLoading({ ...loading, save: false });
  };

  const questions = useMemo(() => data?.questions, [data?.questions]);
  return (
    <div className="w-full flex ml-10 overflow-auto" style={{ height: "60vh" }}>
      {!isFetching && !isFetchinAnswers ? (
        <Form
          form={form}
          name="advanced_search"
          layout="vertical"
          colon={false}
          onFinish={onFinish}
        >
          <>
            {questions?.map((question: any) => (
              <Question
                disabled={!edit}
                questionId={question.id}
                type={question.type}
                header={question.header}
                question={question.title}
                options={question.options}
                otherOption={question.otherOption}
                required={question.required}
              />
            ))}
          </>

          <div
            style={{ textAlign: "right" }}
            className="pr-8 pb-4 sticky bottom-0"
          >
            <Space size="small">
              <Popconfirm
                placement="topLeft"
                title={" Todos as respostas  serão apagadas!! Confirma? "}
                disabled={!hasMinimumPermissions("checklist", user)}
                okText="Apagar"
                cancelText="Cancelar"
                okType="danger"
                onConfirm={() => form.resetFields()}
              >
                <Button
                  disabled={!hasMinimumPermissions("checklist", user)}
                  className="mr-10"
                  danger
                  title="limpar"
                >
                  Limpar
                </Button>
              </Popconfirm>
              <Button
                onClick={handlePrint}
                size="middle"
                title="imprimir"
                type="primary"
                loading={loading.print}
              >
                Imprimir
              </Button>
              <Email
                loading={loading.email}
                onConfirm={sendByEmail}
                setDestination={setDestination}
              />

              <SaveButton
                editing={edit}
                setEditing={setEdit}
                loading={loading.save}
              />
            </Space>
          </div>
          <Divider />
        </Form>
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

export default Wrapper;
