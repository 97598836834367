import { renderDateFormated, truncateString } from "../../../utils";

import {
  CalendarOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Empty, Image, Modal, Popconfirm, Tooltip } from "antd";
import {
  ChangeEvent,
  Fragment,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReferenceType } from "../../../interfaces";
import { TicketContext } from "../contexts/TicketContext";
import { useQuery } from "react-query";
import { api } from "../../../config";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../interfaces/users";
import {
  deleteFile,
  downloadFile,
  legacyDownload,
  uploadSingleFile,
} from "../../../utils/helpers/attachmentHelpers";
import { isSharedComponentDisabled } from "../../../utils/helpers/userHelpers";

const RenderItem = ({
  ticketId,
  title,
  details,
  date,
  id,
  external = false,
  type = "checklist",
  disabled = false,
}: {
  ticketId: number;
  title: string;
  details: string;
  date: string;
  id: number;
  external?: boolean;
  type?: "checklist" | "measuring";
  disabled?: boolean;
}) => {
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const { refetch } = useQuery(
    ["tickets"],
    () => api(token).get(`ticket/checklist/${ticketId}`),
    {
      refetchOnMount: false,
    }
  );
  const handleDelete = async (id: number) => {
    await deleteFile(id, token);
    refetch();
    toast.success("Anexo deletado com sucesso!");
  };

  const isDisabled = useMemo(
    () => external || isSharedComponentDisabled(type, user, disabled),
    [disabled, external, type, user]
  );

  return (
    <div
      key={id.toString()}
      className="flex justify-between h-9 border-b hover:bg-slate-100"
    >
      <div className="text-md flex flex-row items-center ">
        {title}

        <p className="text-xs text-gray-500 ml-2 mb-3">{details}</p>
      </div>

      <div className="flex flex-row">
        <p className="text-xs text-gray-500 mt-2 mr-2">
          <CalendarOutlined className=" m-2 " />
          {date}
        </p>
        <Tooltip placement="topLeft" title="Download">
          <Button
            className="m-1 p-1 mt-2"
            size="small"
            shape="circle"
            icon={<CloudDownloadOutlined />}
            onClick={() =>
              external
                ? legacyDownload(token, id, title)
                : downloadFile(id, token)
            }
          />
        </Tooltip>
        <Tooltip
          placement="topLeft"
          title={external ? "Não é possível deletar anexos legado" : "Deletar"}
        >
          <Popconfirm
            onConfirm={() => handleDelete(id)}
            placement="topLeft"
            title={"Confirma?"}
            disabled={isDisabled}
            okText="Apagar"
            cancelText="Cancelar"
          >
            <Button
              className={`m-1 p-1 ${external ? "" : "mt-2"}`}
              size="small"
              disabled={isDisabled}
              danger
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Tooltip>
      </div>
    </div>
  );
};

export const AddAttachment = (
  type: "checklist" | "measuring",
  referenceType: ReferenceType,
  ticketId: string,
  fieldReportId?: string,
  disabled = false
) => {
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const [loading, setLoading] = useState(false);
  const { refetch } = useQuery(
    [type === "checklist" ? "tickets" : "tickets/measuring"],
    () =>
      api(token).get(
        `ticket/${type}/${type === "checklist" ? ticketId : fieldReportId}`
      ),
    {
      refetchOnMount: false,
    }
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    inputRef.current?.click();
  };
  const handleUpload =
    (referenceType: ReferenceType, fieldReportId?: string) =>
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        setLoading(true);
        await uploadSingleFile(
          e.target.files[0],
          ticketId ?? undefined,
          referenceType,
          token,
          fieldReportId,
          fieldReportId
        );
        setLoading(false);
      }
      refetch();
      toast.success("Upload realizado com sucesso");
    };

  const canUpload = () => {
    if (type === "checklist") {
      if (referenceType === ReferenceType.MEASURING) {
        return fieldReportId === "null" ? false : true;
      }
    }
    return true;
  };

  return (
    <>
      {referenceType !== ReferenceType.INITIAL_PROJECT && (
        <>
          <Tooltip
            placement="topLeft"
            title={
              canUpload()
                ? "Adicionar"
                : "Esta venda ainda não possui um ticket de medição atribuido"
            }
          >
            <Button
              type="link"
              disabled={
                !canUpload() || isSharedComponentDisabled(type, user, disabled)
              }
              icon={<PlusCircleOutlined spin={loading} />}
              onClick={handleSelectFile}
              loading={loading}
            />
          </Tooltip>
          <input
            onChange={handleUpload(referenceType, fieldReportId)}
            ref={inputRef}
            type="file"
            style={{ display: "none" }}
          />
        </>
      )}
    </>
  );
};

interface AttachmentsInterfaceProps {
  ticketInfo?: any;
  disabled?: boolean;
}

function Attachments({
  ticketInfo,
  disabled = false,
}: AttachmentsInterfaceProps) {
  const ticketContext = useContext(TicketContext) as any;
  const { token } = useSelector((state: AuthenticationState) => state);
  const ticket = ticketInfo ? ticketInfo : ticketContext.ticket;
  const { data, isFetching } = useQuery(["initialAttachments"], () =>
    api(token).get(`attachments-legacy/${ticket.saleId}`)
  );
  const [galleryIsOpen, setGalleryIsOpen] = useState<boolean>(false);

  const handleOpenGallery = () => {
    setGalleryIsOpen(true);
  };

  const handleCloseGallery = () => {
    setGalleryIsOpen(false);
  };

  const initialProjectAttachment: any = useMemo(
    () => (!isFetching && data ? data : undefined),
    [data, isFetching]
  );
  const questionnaireAttachment = ticket.attachments?.filter(
    (attachment: any) => attachment.referenceType === "QUESTIONNAIRE"
  );

  const measuresAttachment = ticket.attachments?.filter(
    (attachment: any) => attachment.referenceType === "MEASURING"
  );
  const otherAttachment = ticket.attachments?.filter(
    (attachment: any) => attachment.referenceType === "OTHER"
  );
  const finalProjectAttachment = ticket.attachments?.filter(
    (attachment: any) => attachment.referenceType === "FINAL_PROJECT"
  );

  console.log(measuresAttachment);

  return (
    <>
      {ticketInfo ? (
        <>
          <Modal
            open={galleryIsOpen}
            onCancel={handleCloseGallery}
            cancelText={"Fechar"}
            okButtonProps={{ style: { display: "none" } }}
            width={1000}
          >
            <div className="text-lg font-bold my-3">Fotos</div>

            <div className="w-full grid grid-cols-2 flex-wrap gap-3">
              {measuresAttachment.map((attachment: any) => (
                <div key={attachment.id} className="border border-black">
                  <Image
                    width={"100%"}
                    height="auto"
                    src={attachment.url}
                    alt="msattachment"
                  />

                  <p>{attachment.originalFileName}</p>
                </div>
              ))}
            </div>
          </Modal>
          <div>
            <Card
              className="mb-3 overflow-auto h-56 "
              title={
                <>
                  Anexos Medição -
                  <Button
                    disabled={!measuresAttachment.length}
                    size="small"
                    className="ml-2"
                    onClick={handleOpenGallery}
                  >
                    Abrir Galeria
                  </Button>
                </>
              }
              size="small"
              extra={AddAttachment(
                "measuring",
                ReferenceType.MEASURING,
                String(ticket.ticketId),
                String(ticket.id),
                disabled
              )}
              headStyle={{ color: "#1677FF" }}
            >
              {measuresAttachment ? (
                measuresAttachment?.map((attachment: any) => (
                  <Fragment key={attachment.id}>
                    <RenderItem
                      ticketId={ticket.ticketId ?? ticket.id}
                      title={truncateString(attachment.originalFileName, 70)}
                      details={attachment.creator?.fullName ?? "sistema"}
                      date={renderDateFormated(attachment.createdAt)}
                      id={attachment.id}
                      disabled={disabled}
                      type="measuring"
                    />
                  </Fragment>
                ))
              ) : (
                <>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Sem anexos"
                  />
                </>
              )}
            </Card>
          </div>
        </>
      ) : (
        <div className=" overflow-auto" style={{ height: "60vh" }}>
          <Card
            className="mb-3"
            title="Projeto Inicial"
            size="small"
            extra={AddAttachment(
              "checklist",
              ReferenceType.INITIAL_PROJECT,
              String(ticket.id)
            )}
            headStyle={{ color: "#1677FF" }}
          >
            {!isFetching && initialProjectAttachment && (
              <>
                {initialProjectAttachment?.map?.((attachment: any) => {
                  return (
                    <Fragment key={attachment.id}>
                      <RenderItem
                        ticketId={ticket.ticketId ?? ticket.id}
                        title={truncateString(attachment.originalFileName, 70)}
                        details={"Sistema"}
                        date={renderDateFormated(attachment.createdAt)}
                        id={attachment.id}
                        external
                      />
                    </Fragment>
                  );
                })}
              </>
            )}
          </Card>
          <Card
            className="mb-3"
            title="Medição"
            size="small"
            extra={AddAttachment(
              "checklist",
              ReferenceType.MEASURING,
              String(ticket.id),
              String(ticket.fieldReportId)
            )}
            headStyle={{ color: "#1677FF" }}
          >
            {measuresAttachment?.map((attachment: any) => (
              <Fragment key={attachment.id}>
                <RenderItem
                  ticketId={ticket.ticketId ?? ticket.id}
                  title={truncateString(attachment.originalFileName, 70)}
                  details={attachment.creator?.fullName ?? "sistema"}
                  date={renderDateFormated(attachment.createdAt)}
                  id={attachment.id}
                />
              </Fragment>
            ))}
          </Card>
          <Card
            className="mb-3"
            title="Projeto Final"
            size="small"
            extra={AddAttachment(
              "checklist",
              ReferenceType.FINAL_PROJECT,
              String(ticket.id)
            )}
            headStyle={{ color: "#1677FF" }}
          >
            {finalProjectAttachment?.map((attachment: any) => (
              <Fragment key={attachment.id}>
                <RenderItem
                  ticketId={ticket.ticketId ?? ticket.id}
                  title={truncateString(attachment.originalFileName, 70)}
                  details={attachment.creator?.fullName ?? "sistema"}
                  date={renderDateFormated(attachment.createdAt)}
                  id={attachment.id}
                />
              </Fragment>
            ))}
          </Card>
          <Card
            className="mb-3"
            title="Questionários"
            size="small"
            extra={AddAttachment(
              "checklist",
              ReferenceType.QUESTIONNAIRE,
              String(ticket.id)
            )}
            headStyle={{ color: "#1677FF" }}
          >
            {questionnaireAttachment?.map((attachment: any) => (
              <Fragment key={attachment.id}>
                <RenderItem
                  ticketId={ticket.ticketId ?? ticket.id}
                  title={truncateString(attachment.originalFileName, 70)}
                  details={attachment.creator?.fullName ?? "Sistema"}
                  date={renderDateFormated(attachment.createdAt)}
                  id={attachment.id}
                />
              </Fragment>
            ))}
          </Card>
          <Card
            className="mb-3"
            title="Outros"
            size="small"
            extra={AddAttachment(
              "checklist",
              ReferenceType.OTHER,
              String(ticket.id)
            )}
            headStyle={{ color: "#1677FF" }}
          >
            {otherAttachment?.map((attachment: any) => (
              <Fragment key={attachment.id}>
                <RenderItem
                  ticketId={ticket.ticketId ?? ticket.id}
                  title={truncateString(attachment.originalFileName, 70)}
                  details={attachment.creator?.fullName ?? "sistema"}
                  date={renderDateFormated(attachment.createdAt)}
                  id={attachment.id}
                />
              </Fragment>
            ))}
          </Card>
        </div>
      )}
    </>
  );
}

export default Attachments;
