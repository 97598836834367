import { Button, Modal } from "antd";
import { api } from "../../../../config";
import { Calendar } from "../../../Calendar/Calendar";
import "../../../Calendar/calendar.css";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../../interfaces/users";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { addHours } from "date-fns";
import { CalendarAppointmentContext } from "../../../Shared/contexts/CalendarAppointmentContext";
import {
  formatPhoneNumber,
  hasMinimumPermissions,
} from "../../../../utils/helpers/userHelpers";
import { NewEventModal } from "../../../Shared/Components/NewEventModal/NewEventModal";
import { TicketViewModel } from "../../../../interfaces";

dayjs.extend(duration);

interface CalendarAppointmentProps {
  id: number;
  refetch?: any;
  ticket?: TicketViewModel;
}

interface EventFormProps {
  description: string;
  start?: Date;
  end?: Date;
  notes?: string;
  ticketId?: number;
  agendaId?: number;
}

const defaultForm: EventFormProps = {
  description: "",
  start: undefined,
  end: undefined,
  notes: "",
  ticketId: undefined,
  agendaId: undefined,
};

export const CalendarAppointment: React.FC<CalendarAppointmentProps> = ({
  id,
  refetch,
  ticket,
}) => {
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const [formData, setFormData] = useState({
    ...defaultForm,
    description: ticket?.saleId
      ? `Venda - ${ticket?.saleId}${
          ticket.client.phoneNumber
            ? ` - ${formatPhoneNumber(ticket.client.phoneNumber)} - ${
                ticket.client.name
              }`
            : ""
        }`
      : "",
  });
  const [okLoading, setOkLoading] = useState<any>(false);
  const [step, setStep] = useState<1 | 2>(1);
  const { popIsOpen, onPopClose, onPopOpen } = useContext(
    CalendarAppointmentContext
  ) as any;

  const onFormChange = (
    id: string | string[],
    value: string | Date | string[] | Date[]
  ) => {
    if (typeof id === "object") {
      let tempForm = {};

      id.forEach((id, i) => {
        tempForm = { ...tempForm, [id]: (value as string[])[i] };
      });

      return setFormData({ ...formData, ...tempForm });
    }
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async () => {
    if (!formData.agendaId) {
      return toast.error("É obrigatória a seleção da agenda");
    }
    if (!hasMinimumPermissions("calendar", user))
      return toast.error(
        "Este usuário não tem permissão para realizar alterações em eventos"
      );

    setOkLoading(true);

    try {
      await api(token).post("event/schedule", {
        ...formData,
        ticketId: id,
      });

      handleClose();
      setOkLoading(false);
      setFormData(defaultForm);
      toast.success("Agendamento realizado com sucesso");
      refetch?.();
    } catch (error: any) {
      setOkLoading(false);
      toast.error(
        "Ocorreu um erro ao agendar o evento, por favor tente novamente mais tarde"
      );
    }
  };

  const handleChangeStep = (newStep: 1 | 2, date?: Date) => {
    setStep(newStep);
    if (date) {
      setFormData({ ...formData, start: date, end: addHours(date, 1) });
    }
  };

  const handleClose = () => {
    onPopClose(false);
    setStep(1);
  };

  return (
    <>
      <Modal
        width={1000}
        title="Confirmação"
        open={popIsOpen && step === 1}
        footer={null}
        confirmLoading={okLoading}
        onCancel={handleClose}
        style={{ overflowY: "auto" }}
      >
        <Calendar popover={true} handleModalShow={handleChangeStep} />
      </Modal>

      <NewEventModal
        isOpen={popIsOpen && step === 2}
        formData={formData}
        onChange={onFormChange}
        loading={okLoading}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />

      <Button
        size="small"
        disabled={!hasMinimumPermissions("checklist", user)}
        onClick={() => onPopOpen()}
      >
        Agendar
      </Button>
    </>
  );
};
