import axios from "axios";

export const axiosClient = (token: string) => axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
    "Authorization": `bearer ${token}`,
  },
});

export const api = (token: string) => 
  process.env.REACT_APP_API_REMOTE_SERVER === "api"
    ? {
        get: async (endpoint: string, params?: any) => {
          const {
            data: { data },
          } = await axiosClient(token).get(`api/${endpoint}`, { params });
          return data;
        },
        post: async (
          endpoint: string,
          body: any,
          headers?: {
            "Content-Type": string;
            Authorization?: string;
          }
        ) => {
          const {
            data: { data },
          } = await axiosClient(token).post(`api/${endpoint}`, body, { headers });
          return data;
        },
        put: async (endpoint: string, params?: any) => {
          const {
            data: { data },
          } = await axiosClient(token).put(`api/${endpoint}`, params);
          return data;
        },
        delete: async (endpoint: string, params?: any) => {
          const {
            data: { data },
          } = await axiosClient(token).delete(`api/${endpoint}`, params);
          return data;
        },
      }
    : {
        get: async (endpoint: string, params?: any) => {
          const { data } = await axiosClient(token).get(endpoint, { params });
          return data;
        },
        post: async (endpoint: string, body: any) => {
          const { data } = await axiosClient(token).post(endpoint, body);
          return data;
        },
        put: async (endpoint: string, params?: any) => {
          const { data } = await axiosClient(token).put(endpoint, params);
          return data;
        },
        delete: async (endpoint: string, params?: any) => {
          const { data } = await axiosClient(token).delete(endpoint, params);
          return data;
        },
      };
