import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { updatePermissions } from "../../utils";
import { PermissionsForm } from "../Profile/PermissionsForm";
import { toast } from "react-toastify";

interface PermissionsModalProps {
  isOpen: boolean;
  user: any;
  onClose: () => void;
  refetch: any;
}

const defaultForm = {
  checklist: "NONE",
  measuring: "NONE",
  calendar: "NONE",
  users: "NONE",
  app: "NONE",
  assemble_app: "NONE",
};

export const PermissionsModal = ({
  isOpen,
  user,
  onClose,
  refetch,
}: PermissionsModalProps) => {
  const { token } = useSelector((state: AuthenticationState) => state);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(defaultForm);

  useEffect(() => {
    let modules: any = {};

    user?.modules?.forEach(({ moduleName, permission }: any) => {
      console.log(moduleName);
      modules[moduleName.toLowerCase()] = permission;
    });

    setFormData({ ...defaultForm, ...modules });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isOpen]);

  const handleOnFormChange = ({ target: { id, value } }: any) => {
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async () => {
    if (formData.app === "USER" && formData.assemble_app === "USER") {
      return toast.error(
        "Usuário não pode ter acesso aos dois tipos de aplicativo, favor selecionar apenas um"
      );
    }
    setLoading(true);
    await updatePermissions(token, user.id, formData);
    refetch();
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      centered
      open={isOpen}
      width={"40%"}
      onCancel={onClose}
      title="Permissões"
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <PermissionsForm formData={formData} onChange={handleOnFormChange} />
    </Modal>
  );
};
