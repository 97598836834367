import Details from "../../../Shared/Components/Details";
import Attachments from "../../../Shared/Components/Attachments";
import Questionnaire from "./Questionnaire";
import ScheduleHistory from "./ScheduleHistory";
import { MeasuringTab } from "./MeasuringTab";
import { EmptyState } from "../../../Shared/Components/EmptyState/EmptyState";

function navigationPages(ticket: any) {
  return [
    {
      key: "1",
      label: `Informações Gerais`.toUpperCase(),
      children: <Details />,
    },
    {
      key: "2",
      label: `Agendamentos e Histórico`.toUpperCase(),
      children: <ScheduleHistory />,
    },
    {
      key: "3",
      label: `Ticket de Medição`.toUpperCase(),
      children: (
        <>{ticket.fieldReport.length ? <MeasuringTab /> : <EmptyState />}</>
      ),
    },
    {
      key: "4",
      label: `Questionários`.toUpperCase(),
      children: <Questionnaire />,
    },
    {
      key: "5",
      label: `Anexos`.toUpperCase(),
      children: <Attachments />,
    },
  ];
}

export default navigationPages;
