interface ViewControlButtonProps {
  onClick: () => void;
  width?: "w-8" | "w-10" | "w-16" | "w-20";
  label: string;
}

export const ViewControlButton = ({
  onClick,
  width = "w-8",
  label,
}: ViewControlButtonProps) => {
  return (
    <div>
      <button
        className={`bg-gray-800 hover:bg-slate-500 transition-all text-white border-0 h-8 ${width} font-extrabold cursor-pointer`}
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
};
