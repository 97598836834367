import { useContext } from "react";
import { TicketContext } from "../../../../Shared/contexts/TicketContext";
import TicketMeasure from "../../../../Measuring/TicketModal/TicketMeasure";
import { Tabs } from "antd";

export const MeasuringTab = () => {
  const { ticket } = useContext(TicketContext) as any;

  return (
    <div className="w-full  overflow-y-scroll" style={{ height: "65vh" }}>
      <Tabs
        tabPosition="left"
        items={ticket.fieldReport?.map((fieldReport: any) => ({
          label: fieldReport.id,
          key: fieldReport.id,
          children: (
            <TicketMeasure type="checklist" disabled ticket={fieldReport} />
          ),
        }))}
      />
    </div>
  );
};
