import { useState, useRef, useEffect, useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import { EventContent } from "./eventContent";
import "./calendar.css";

import { useQuery } from "react-query";
import { api } from "../../config";

import { Button, Input, Popconfirm, Skeleton } from "antd";
import Filter from "./Filter";

import dbData from "../../backup.json";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { PlusCircleOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { NewEventModal } from "../Shared/Components/NewEventModal/NewEventModal";
import { add, addHours, endOfWeek, format, startOfWeek } from "date-fns";
import { ViewControl } from "./ViewControl/ViewControl";
import { useReactToPrint } from "react-to-print";
import { hasMinimumPermissions } from "../../utils/helpers/userHelpers";

interface CalendarInterface {
  popover?: Boolean;
  handleModalShow?: any;
}

const defaultForm: {
  start?: Date;
  end?: Date;
  description: string;
  agendaId?: number;
  ticketId?: number;
  notes?: string;
} = {
  start: undefined,
  end: undefined,
  description: "",
  notes: "",
  agendaId: undefined,
};

export const Calendar: React.FC<CalendarInterface> = ({
  popover,
  handleModalShow,
}) => {
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const [newEventModal, setNewEventModal] = useState(false);
  const [editing, setEditing] = useState<number | false>(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(defaultForm);
  const [currentViewDate, setCurrentViewDate] = useState<string | undefined>();
  const [view, setView] = useState<"day" | "week" | "list">("day");
  const [newAgendaInfo, setNewAgendaInfo] = useState({
    name: "",
    color: "",
  });
  const [queryParams, setQueryParams] = useState({});
  const [currentSetting, setCurrentSetting] = useState<any>();
  const { isLoading, data, isError, refetch } = useQuery(
    ["calendar", queryParams],
    () => api(token).get("/events", queryParams)
  );

  const printWeekRef = useRef(null);
  const printDayRef = useRef(null);
  const handleWeekPrint = useReactToPrint({
    content: () => printWeekRef.current,
    pageStyle: "scale: 0.8",
  });
  const handleDayPrint = useReactToPrint({
    content: () => printDayRef.current,
    pageStyle: "scale: 0.8",
  });

  const handlePrint = () => {
    if (view === "list" || view === "week") {
      handleWeekPrint();
    } else {
      handleDayPrint();
    }
  };

  const backupData: any = dbData["events"];

  const calendarRef = useRef<any>(null);

  const calendarRefs = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
  ];

  const handleFilter = (filter: any) => {
    setQueryParams({ ...filter });
  };

  const handleEventClick = (event: any) => {
    const eventTicketId = event.extendedProps.ticketId ?? undefined;
    if (!hasMinimumPermissions("calendar", user)) {
      if (eventTicketId) {
        return window.open(`/checklist?id=${eventTicketId}`);
      } else {
        return toast.error(
          "Este usuário não tem permissão para realizar alterações em eventos"
        );
      }
    }
    setEditing(event.id);
    setFormData({
      start: event.start,
      end: event.end,
      description: event.extendedProps.description ?? "",
      agendaId: event.extendedProps.agendaId,
      ticketId: event.extendedProps.ticketId ?? undefined,
      notes: event.extendedProps.notes ?? "",
    });

    setNewEventModal(true);
  };

  const events = useMemo(() => {
    let tempEvent: any = {};
    data?.forEach?.((event: any) => {
      tempEvent = {
        ...tempEvent,
        [event.title]: [...(tempEvent[event.title] ?? []), event],
      };
    });
    tempEvent = {
      ...tempEvent,
      Novo: [],
    };
    return tempEvent;
  }, [data]);

  useEffect(() => {
    if (!popover) {
      const calendarApi = calendarRef.current?.getApi();
      calendarApi && calendarApi.gotoDate(currentSetting?.start ?? new Date());
    }

    setTimeout(() => {
      if (view === "day") {
        const today = !currentViewDate
          ? format(new Date(), "yyyy-MM-dd")
          : currentViewDate;
        return calendarRefs.forEach((ref: any, i: number) => {
          const calendarApi = ref?.current?.getApi();
          if (today) {
            setCurrentViewDate(today);
            calendarApi?.changeView("timeGridDay", today);
          }
        });
      }
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (view === "list") {
      const calendarApi = calendarRef?.current?.getApi();
      calendarApi?.changeView(
        "listDay",
        currentViewDate ?? format(new Date(), "yyyy-MM-dd")
      );
    } else if (view === "week") {
      const calendarApi = calendarRef?.current?.getApi();
      const date = currentViewDate
        ? new Date(currentViewDate.replace(/-/g, "/"))
        : new Date();
      calendarApi?.changeView("timeGrid", {
        start: format(startOfWeek(date), "yyyy-MM-dd"),
        end: format(add(endOfWeek(date), { days: 1 }), "yyyy-MM-dd"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const getDate = () => {
    const calendarApi = calendarRef.current?.getApi();
    return calendarApi ? calendarApi.getDate() : new Date();
  };

  const saveDate = (event: any) => {
    setCurrentSetting({
      ...event,
      start: getDate(),
    });
  };

  const handleNewEventModal = (info: any) => {
    if (!hasMinimumPermissions("calendar", user))
      return toast.error(
        "Este usuário não tem permissão para realizar alterações em eventos"
      );
    setFormData({ ...formData, start: info, end: addHours(info, 1) });
    setNewEventModal(true);
  };

  if (isLoading) return <Skeleton active />;

  const blockNewEvent = () => {
    if (currentSetting?.view?.type === "dayGridMonth") return false;
    return true;
  };

  const handleNewAgenda = async () => {
    try {
      await api(token).post("agenda", {
        title: newAgendaInfo.name,
        color: newAgendaInfo.color,
      });
      window.location.href = "/calendario";
    } catch (error: any) {
      console.error(error.message);
      toast.error("ERROR");
    }
  };

  const onFormChange = (
    id: string | string[],
    value: string | Date | string[] | Date[]
  ) => {
    if (typeof id === "object") {
      let tempForm = {};

      id.forEach((id, i) => {
        tempForm = { ...tempForm, [id]: (value as string[])[i] };
      });

      return setFormData({ ...formData, ...tempForm });
    }
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async () => {
    if (!formData.agendaId) {
      return toast.error("É obrigatória a seleção da agenda");
    }
    if (!hasMinimumPermissions("calendar", user))
      return toast.error(
        "Este usuário não tem permissão para realizar alterações em eventos"
      );

    setLoading(true);

    try {
      if (editing) {
        await api(token).put(`event/${editing}`, formData);
        setEditing(false);
      } else {
        await api(token).post("event", formData);
      }

      setNewEventModal(false);
      setLoading(false);
      setFormData(defaultForm);
      toast.success("Agendamento realizado com sucesso");
      refetch?.();
    } catch (error: any) {
      setLoading(false);
      toast.error(
        "Ocorreu um erro ao agendar o evento, por favor tente novamente mais tarde"
      );
    }
  };

  const handleDeleteEvent = async () => {
    if (!hasMinimumPermissions("calendar", user))
      return toast.error(
        "Este usuário não tem permissão para realizar alterações em eventos"
      );
    setLoading(true);
    try {
      if (editing) {
        await api(token).delete(`event/${editing}`, formData);
        setEditing(false);
        setFormData(defaultForm);
        toast.success("Agendamento excluído com sucesso");
        refetch?.();
      }
      setNewEventModal(false);
      setLoading(false);
    } catch {
      setLoading(false);
      toast.error(
        "Ocorreu um erro ao excluir o evento, por favor tente novamente mais tarde"
      );
    }
  };

  return (
    <div className="w-full">
      {!popover && (
        <>
          <h1>
            Calendário{" "}
            <Popconfirm
              className="absolute right-5 top-12 mt-6"
              placement="topLeft"
              title="Criar Calendário"
              description={
                <>
                  <Input
                    placeholder="Nome do calendário"
                    type="text"
                    onChange={(event: any) =>
                      setNewAgendaInfo((prevState) => ({
                        ...prevState,
                        name: event.target.value,
                      }))
                    }
                  />
                  <div className="flex m-5">
                    Cor:
                    <Input
                      className="ml-2"
                      type="color"
                      onChange={(event: any) =>
                        setNewAgendaInfo((prevState) => ({
                          ...prevState,
                          color: event.target.value,
                        }))
                      }
                    />
                  </div>
                </>
              }
              okText="Enviar"
              cancelText="Cancelar"
              onConfirm={handleNewAgenda}
            >
              <Button
                type="link"
                title="Criar Calendário"
                size="large"
                disabled={false}
                icon={<PlusCircleOutlined />}
              />
            </Popconfirm>
          </h1>
        </>
      )}

      <Filter data={isError ? backupData : data} setFilter={handleFilter} />
      <ViewControl
        calendarRef={calendarRef}
        calendarRefs={calendarRefs}
        currentViewDate={currentViewDate}
        setCurrentViewDate={setCurrentViewDate}
        setView={setView}
        view={view}
        onPrint={handlePrint}
      />
      <NewEventModal
        formData={formData}
        isOpen={newEventModal}
        loading={loading}
        onChange={onFormChange}
        onSubmit={handleSubmit}
        onClose={() => {
          setEditing(false);
          setFormData(defaultForm);
          setNewEventModal(false);
        }}
        onDelete={handleDeleteEvent}
        editing={editing}
      />

      {view === "week" || view === "list" ? (
        <div className="print-area" ref={printWeekRef}>
          <FullCalendar
            height={popover ? undefined : "auto"}
            ref={calendarRef}
            contentHeight={popover ? 400 : 1000}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: undefined,
              center: "title",
              right: undefined,
            }}
            allDaySlot={false}
            slotMinTime={"08:00:00"}
            slotMaxTime={"19:00:00"}
            slotLabelInterval={{ hours: 1 }}
            slotDuration={{ hour: 1 }}
            initialView={"timeGridWeek"}
            weekends={true}
            eventClassNames={"event"}
            eventOrder={view === "list" ? "start" : "title"}
            slotLaneClassNames="lane-height"
            eventOrderStrict={true}
            eventMaxStack={8}
            eventClick={(info: any) => handleEventClick(info.event)}
            locale={ptBrLocale}
            events={data ?? []}
            datesSet={(event) => saveDate(event)}
            eventContent={EventContent}
            dateClick={(info) =>
              popover
                ? handleModalShow(2, info.date)
                : blockNewEvent()
                ? handleNewEventModal(info.date)
                : alert(
                    "Não é possível criar eventos no modo mês, favor trocar para modo semana ou dia."
                  )
            }
          />
        </div>
      ) : (
        <>
          <div
            ref={printDayRef}
            className={`print-area-day flex flex-row items-end`}
          >
            {Object.entries(events).map(([key, value], i) => (
              <div key={key} className="block w-full">
                <div>{(value as any)?.[0]?.title as string}</div>
                <FullCalendar
                  height={popover ? undefined : "auto"}
                  ref={calendarRefs[i]}
                  contentHeight={popover ? 400 : 1000}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: undefined,
                    center: undefined,
                    right: undefined,
                  }}
                  slotLabelClassNames={i > 0 ? "fc-hidden-label" : undefined}
                  dayHeaders={false}
                  slotLabelInterval={{ hours: 1 }}
                  slotDuration={{ hour: 1 }}
                  initialView="timeGridDay"
                  allDaySlot={false}
                  slotMinTime={"08:00:00"}
                  slotMaxTime={"19:00:00"}
                  weekends={true}
                  eventOrder="title"
                  eventOrderStrict={true}
                  eventMaxStack={8}
                  slotLaneClassNames="lane-height"
                  allDayClassNames={i > 0 ? "fc-hidden-label" : undefined}
                  eventClick={(info: any) => handleEventClick(info.event)}
                  locale={ptBrLocale}
                  events={value ?? []}
                  datesSet={(event) => saveDate(event)}
                  eventContent={EventContent}
                  dateClick={(info) =>
                    popover
                      ? handleModalShow(2, info.date)
                      : blockNewEvent()
                      ? handleNewEventModal(info.date)
                      : alert(
                          "Não é possível criar eventos no modo mês, favor trocar para modo semana ou dia."
                        )
                  }
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
