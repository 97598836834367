import { useState } from "react";
import { Button, DatePicker, Form, FormInstance, Input, Select } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import { hasMinimumPermissions } from "../../utils/helpers/userHelpers";
import {
  TicketCategoriesChecklist,
  TicketCategoriesMeasuring,
} from "../../utils";

type FormDetailsProps = {
  form: FormInstance;
  onFinish: (values: any) => Promise<void>;
};

interface FiltersFormProps {
  formDetails: FormDetailsProps;
  userList: any;
}

export const FiltersForm = ({ formDetails, userList }: FiltersFormProps) => {
  const { form, onFinish } = formDetails;
  const { usersData, usersIsFetching } = userList;
  const [statusList, setStatusList] = useState<any>([]);

  const { RangePicker } = DatePicker;

  const handleStatusList = (value: any) => {
    setStatusList(
      Object.values(
        value === "checklist"
          ? TicketCategoriesChecklist
          : TicketCategoriesMeasuring
      ).map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
  };

  return (
    <Form
      form={form}
      name="reports_filters"
      onFinish={onFinish}
      className="w-12/12 m-auto bg-white p-5 rounded-lg"
    >
      <div className="flex row pt-4 w-full">
        <Form.Item
          name="type"
          className="w-4/12 mr-1"
          rules={[{ required: true, message: "Campo obrigatório" }]}
        >
          <Select
            placeholder="Tipo"
            options={[
              { value: "measuring", label: "Medição" },
              { value: "checklist", label: "Checklist" },
            ]}
            onChange={(value) => handleStatusList(value)}
          />
        </Form.Item>
        <Form.Item name="keywords" className="w-full">
          <Input placeholder="Título, descrição ou contato" />
        </Form.Item>
      </div>
      <div className="flex justify-between w-12/12">
        <Form.Item name="status" className="w-1/4 mr-1">
          <Select placeholder="Etapa" options={statusList} allowClear={true} />
        </Form.Item>
        <Form.Item name="ownerId" className="w-1/4 mr-1">
          <Select
            placeholder="Responsável"
            allowClear={true}
            disabled={usersIsFetching}
            options={(usersData as any[])
              ?.filter((item) => hasMinimumPermissions("app", item))
              ?.map((item: any) => {
                return {
                  value: item.id,
                  label: item.fullName,
                };
              })}
          />
        </Form.Item>
        <Form.Item name="saleId" className="w-1/3">
          <Input placeholder="Código da Venda" />
        </Form.Item>
      </div>
      <br />
      <div className="flex justify-between w-full">
        <Form.Item name="clientName" className="w-1/3">
          <Input placeholder="Cliente" />
        </Form.Item>
        <Form.Item name="ticketId" className="w-1/3">
          <Input placeholder="Código do Ticket" />
        </Form.Item>
      </div>
      <br />
      <div className="flex justify-between w-12/12">
        <div className="w-1/3">
          <Form.Item name="createdAt" label="Data de Inclusão">
            <RangePicker
              locale={locale}
              className="w-full"
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </div>
        <div className="w-1/3">
          <Form.Item name="endDate" label="Data de Conclusão">
            <RangePicker
              className="w-full"
              locale={locale}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </div>
      </div>
      <div className="w-1/3">
        {form.getFieldValue("type") === "measuring" && (
          <Form.Item name="filledAt" label="Data de Preenchimento">
            <RangePicker
              className="w-full"
              locale={locale}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        )}
      </div>
      <Form.Item shouldUpdate>
        {() => (
          <Button type="primary" htmlType="submit">
            Buscar
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
