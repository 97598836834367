import { Button } from "antd";
import { hasMinimumPermissions } from "../../../../../utils/helpers/userHelpers";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../../../interfaces/users";

interface SaveButtonProps {
  loading?: boolean;
  setEditing?: any;
  editing: boolean;
}

export const SaveButton: React.FC<SaveButtonProps> = ({ loading, setEditing, editing }) => {
  const { user } = useSelector((state:AuthenticationState) => state)

  const handleChangeButton = (e: any) => {
    if (!editing) e.preventDefault();
    setEditing?.((prev: boolean) => !prev);
  };

  return (
    <div>
      <Button
        disabled={!hasMinimumPermissions("checklist", user)}
        type="primary"
        htmlType="submit"
        size={"middle"}
        loading={loading}
        onClick={handleChangeButton}
      >
        {editing ? "Salvar" : loading ? "Salvando" : "Editar"}
      </Button>
    </div>
  );
};
