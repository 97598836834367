import { useContext, useMemo, useState } from "react";
import { Card, Button } from "antd";
import { EditTwoTone, SaveTwoTone } from "@ant-design/icons";
import { api } from "../../../config";
import { TicketContext } from "../../Shared/contexts/TicketContext";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../interfaces/users";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { isSharedComponentDisabled } from "../../../utils/helpers/userHelpers";
import TextArea from "antd/es/input/TextArea";

interface DescriptionInterfaceProps {
  ticketInfo?: any;
  disabled?: boolean;
  type?: "checklist" | "measuring";
}

function Description({
  ticketInfo,
  disabled = false,
  type = "checklist",
}: DescriptionInterfaceProps) {
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const ticketType = ticketInfo ? "measuring" : "checklist";
  const ticketContext = useContext(TicketContext) as any;
  const isChecklist = useMemo(() => ticketType === "checklist", [ticketType]);
  const ticket = ticketType === "measuring" ? ticketInfo : ticketContext.ticket;
  const [showTextArea, setShowTextArea] = useState(false);
  const [loading, setLoading] = useState(false);
  const [descriptionText, setDescriptionText] = useState(
    ticket?.[isChecklist ? "description" : "notes"] ?? ""
  );

  const { refetch } = useQuery(
    isChecklist ? "tickets" : "tickets/measuring",
    () => api(token).get(`/ticket/${ticketType}/${ticket.id}`),
    { refetchOnMount: false }
  );

  const saveDescription = async (description: string) => {
    setLoading(true);
    await api(token).put(`/ticket/${ticketType}/${ticket?.id || 0}`, {
      [isChecklist ? "description" : "notes"]: description,
    });
    setShowTextArea(false);
    refetch();
    toast.success("Descrição alterada com sucesso!");
    setLoading(false);
  };

  const onTextAreaChange = (e: any) => {
    setDescriptionText(e.target.value);
  };
  return (
    <div>
      <Card
        className="mb-3"
        title="Descrição"
        size="small"
        headStyle={{ color: " #1677FF" }}
      >
        <div className="flex justify-between items-center">
          <div className="w-full mr-2.5">
            {!showTextArea &&
            (/<\/?[a-z][\s\S]*>/i.test(descriptionText) ||
              descriptionText.match(/\\rtf1\\fbidis/gi)) ? (
              <div dangerouslySetInnerHTML={{ __html: descriptionText }} />
            ) : (
              <TextArea
                placeholder={descriptionText}
                value={showTextArea ? descriptionText : ""}
                disabled={!showTextArea}
                onChange={onTextAreaChange}
                size="small"
                rows={type === "measuring" ? 8 : 3}
              />
            )}

            {/* <TextArea
                placeholder={descriptionText}
                value={showTextArea ? descriptionText : ""}
                disabled={!showTextArea}
              onChange={onTextAreaChange}
              size="small"
              rows={type === "measuring" ? 8 : 3}
              /> */}
          </div>
          <div>
            <Button
              type="link"
              disabled={isSharedComponentDisabled(type, user, disabled)}
              icon={showTextArea ? <SaveTwoTone /> : <EditTwoTone />}
              size={"middle"}
              loading={loading}
              onClick={() =>
                showTextArea
                  ? saveDescription(descriptionText)
                  : setShowTextArea(true)
              }
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Description;
