import { Card, Modal } from "antd";
import { UserInformationForm } from "../Profile/UserInformationForm";
import { PasswordForm } from "../Profile/PasswordForm";
import { FormFooter } from "../Profile/FormFooter";
import { useEffect, useState } from "react";
import { createUser, updateUserInfo } from "../../utils";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { toast } from "react-toastify";
import { PermissionsForm } from "../Profile/PermissionsForm";

interface UserModalProps {
  showEditModal: boolean;
  onClose: () => void;
  user?: any;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
}

interface FormDataProps {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  taticoUsrId?: string;
  checklist?: string;
  measuring?: string;
  calendar?: string;
  users?: string;
  app?: string;
  assemble_app?: string;
}

const defaultForm: FormDataProps = {
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  taticoUsrId: "",
  checklist: "NONE",
  measuring: "NONE",
  calendar: "NONE",
  users: "NONE",
  app: "NONE",
  assemble_app: "NONE",
};

export const UserModal = ({
  showEditModal,
  onClose,
  user,
  refetch,
}: UserModalProps) => {
  const { token } = useSelector((state: AuthenticationState) => state);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormDataProps>(defaultForm);

  const formInitialState = () => {
    let modules = {};
    if (user) {
      console.log(user);
      user.modules?.forEach((module: any) => {
        modules = {
          ...modules,
          [module.moduleName.toLocaleLowerCase()]: module.permission,
        };
      });
    }
    setFormData({
      ...defaultForm,
      fullName: user?.fullName ?? "",
      email: user?.email ?? "",
      phoneNumber: user?.phoneNumber ?? "",
      taticoUsrId: user?.taticoUsrId ?? "",
      ...modules,
    });
  };

  useEffect(() => {
    formInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, showEditModal]);

  const handleOnFormChange = ({ target }: any) => {
    const { id, value } = target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (user) {
      const errors = [];

      delete formData.app;
      delete formData.calendar;
      delete formData.checklist;
      delete formData.users;
      delete formData.measuring;
      delete formData.assemble_app;
      if (formData.taticoUsrId === "") {
        delete formData.taticoUsrId;
      }
      if (formData.password !== "") {
        if (formData.confirmPassword !== formData.password) {
          errors.push(
            "A senha digitada não coincide com a confirmação de senha"
          );
        }
        if (formData.password.length < 6) {
          errors.push("A senha precisa conter pelo menos 6 caracteres");
        }
      }
      if (errors.length) {
        setLoading(false);
        return errors.forEach((error) => {
          return toast.error(error);
        });
      }

      await updateUserInfo(token, user?.id ?? 0, formData);
    } else {
      const errors = [];
      if (
        formData.confirmPassword === "" ||
        formData.email === "" ||
        formData.fullName === "" ||
        formData.password === ""
      )
        errors.push("Todos os campos são obrigatórios");
      if (formData.password.length < 6) {
        errors.push("A senha precisa conter pelo menos 6 caracteres");
      }
      if (formData.confirmPassword !== formData.password) {
        errors.push("A senha digitada não coincide com a confirmação de senha");
      }

      if (errors.length) {
        setLoading(false);
        return errors.forEach((error) => {
          return toast.error(error);
        });
      }
      await createUser(token, formData);
    }
    refetch();
    onClose();
    setLoading(false);
  };

  return (
    <Modal
      className="relative"
      open={showEditModal}
      onCancel={onClose}
      centered
      width="45%"
      footer={null}
    >
      <h1>{`${user ? "Editar" : "Criar"} Usuário`}</h1>
      <div className="flex flex-col gap-4">
        <UserInformationForm
          controllerFormData={formData}
          onFormChange={handleOnFormChange}
          className="w-full"
        />
        <PasswordForm
          controllerFormData={formData}
          onFormChange={handleOnFormChange}
          className="w-full"
        />
        {!user && (
          <Card title="Permissões">
            <PermissionsForm
              onChange={handleOnFormChange}
              formData={formData}
            />
          </Card>
        )}
      </div>
      <FormFooter
        loading={loading}
        onClear={formInitialState}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};
