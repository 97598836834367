import { Profile } from "../modules/Profile";

export const ProfilePage = () => {
  return (
    <>
      <h1>Configurações de Perfil</h1>
      <Profile />
    </>
  );
};
