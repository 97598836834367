import { useContext } from "react";
import { TabNavigationContext } from "../../../Shared/contexts/TabNavigationContext";
import navigationPages from "./NavigationPages";

import { Tabs } from "antd";
import { TicketContext } from "../../../Shared/contexts/TicketContext";

function NavigationTabs() {
  const { activeTab, setActiveTab } = useContext(TabNavigationContext)
  const {ticket} = useContext(TicketContext)
  return (
    <Tabs
      className="h-3/4"
      type="card"
      items={navigationPages(ticket)}
      activeKey={activeTab}
      onChange={(e) => setActiveTab?.(e)}
    />
  );
}

export default NavigationTabs;
