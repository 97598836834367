import { Modal } from "antd";
import ModalContainer from "./ModalContainer";

function TicketModalMeasuring({
  showTicketModal,
  setShowTicketModal,
  ticketId,
}: any) {
  const style = {
    padding: 15,
    height: "90vh",
  };

  const handleCancel = () => {
    setShowTicketModal(false);
  };
  return (
    <Modal
      open={showTicketModal}
      onCancel={handleCancel}
      centered
      width="95%"
      footer={null}
      bodyStyle={style}
    >
      <ModalContainer ticketId={ticketId} />
    </Modal>
  );
}

export default TicketModalMeasuring;
