import {
  add,
  endOfWeek,
  format,
  lastDayOfWeek,
  startOfWeek,
  sub,
} from "date-fns";
import ptBr from "date-fns/locale/pt-BR";
import { ViewControlButton } from "./ViewControlButton";
import { useCallback } from "react";

interface ViewControlProps {
  calendarRef: any;
  calendarRefs: any[];
  currentViewDate: string | undefined;
  setCurrentViewDate: (date: string) => void;
  view: "day" | "week" | "list";
  setView: (view: "day" | "week" | "list") => void;
  onPrint: () => void;
}

export const ViewControl = ({
  calendarRef,
  calendarRefs,
  currentViewDate,
  setCurrentViewDate,
  view,
  setView,
  onPrint,
}: ViewControlProps) => {
  const handleIncrease = () => {
    const today = !currentViewDate
      ? format(new Date(), "yyyy-MM-dd")
      : currentViewDate;
    if (view === "day") {
      return calendarRefs.forEach((ref: any, i: number) => {
        const calendarApi = ref?.current?.getApi();
        if (today) {
          const date = format(
            add(new Date(today.replace(/-/g, "/")), { days: 1 }),
            "yyyy-MM-dd"
          );
          setCurrentViewDate(date);
          calendarApi?.changeView("timeGridDay", date);
        }
      });
    } else if (view === "list") {
      const calendarApi = calendarRef?.current?.getApi();
      if (today) {
        const date = format(
          add(new Date(today.replace(/-/g, "/")), { days: 1 }),
          "yyyy-MM-dd"
        );
        setCurrentViewDate(date);
        calendarApi?.changeView("listDay", date);
      }
    } else {
      const calendarApi = calendarRef?.current?.getApi();
      if (today) {
        const date = add(new Date(today), { days: 7 });
        setCurrentViewDate(format(date, "yyyy-MM-dd"));
        calendarApi?.changeView("timeGrid", {
          start: format(startOfWeek(date), "yyyy-MM-dd"),
          end: format(add(lastDayOfWeek(date), { days: 1 }), "yyyy-MM-dd"),
        });
      }
    }
  };

  const handleDecrease = useCallback(() => {
    const today = currentViewDate ?? format(new Date(), "yyyy-MM-dd");
    let date = format(
      sub(new Date(today.replace(/-/g, "/")), { days: 1 }),
      "yyyy-MM-dd"
    );

    if (view === "day") {
      calendarRefs.forEach((ref: any) => {
        const calendarApi = ref?.current?.getApi();
        calendarApi?.changeView("timeGridDay", date);
      });
    } else if (view === "list") {
      const calendarApi = calendarRef?.current?.getApi();
      const today = currentViewDate ?? format(new Date(), "yyyy-MM-dd");
      if (today) {
        calendarApi?.changeView("listDay", date);
      }
    } else {
      const calendarApi = calendarRef?.current?.getApi();

      const today = currentViewDate ?? calendarApi?.view.activeStart;
      if (today) {
        const date = sub(new Date(today.replace(/-/g, "/")), { days: 7 });
        setCurrentViewDate(format(date, "yyyy-MM-dd"));
        return calendarApi?.changeView("timeGrid", {
          start: format(startOfWeek(date), "yyyy-MM-dd"),
          end: format(add(lastDayOfWeek(date), { days: 1 }), "yyyy-MM-dd"),
        });
      }
    }
    console.log(date);
    setCurrentViewDate(date);
  }, [calendarRef, calendarRefs, currentViewDate, setCurrentViewDate, view]);

  const handleToday = () => {
    const today = format(new Date(), "yyyy-MM-dd");
    if (view === "day") {
      calendarRefs.forEach((ref: any) => {
        const calendarApi = ref?.current?.getApi();
        calendarApi?.changeView("timeGridDay", today);
      });
    } else if (view === "list") {
      const calendarApi = calendarRef?.current?.getApi();
      const today = format(new Date(), "yyyy-MM-dd");
      if (today)
        setCurrentViewDate(format(add(new Date(), { days: 1 }), "yyyy-MM-dd"));
      calendarApi?.changeView("listDay", today);
    } else {
      const calendarApi = calendarRef?.current?.getApi();
      const date = new Date();
      if (date) setCurrentViewDate(format(date, "yyyy-MM-dd"));
      calendarApi?.changeView("timeGrid", {
        start: format(startOfWeek(date), "yyyy-MM-dd"),
          end: format(add(endOfWeek(date), {days: 1}), "yyyy-MM-dd"),
      });
    }
    setCurrentViewDate(today);
  };

  return (
    <div className="w-full justify-center flex h-20 relative text-center">
      <p className="text-xl font-bold">
        {view === "day"
          ? format(
              currentViewDate
                ? new Date(currentViewDate.replace(/-/g, "/"))
                : new Date(),
              "dd/MM/yyyy - EEEE",
              { locale: ptBr }
            )
          : ""}
      </p>

      <div className="absolute left-0 w-56 gap-2 flex mb-2">
        <ViewControlButton onClick={handleDecrease} label="<" />
        <ViewControlButton onClick={handleToday} label="Hoje" width="w-16" />
        <ViewControlButton onClick={handleIncrease} label=">" />
      </div>
      <div className="absolute justify-end right-0 w-72 gap-2 z-0 mb-2 flex">
        <ViewControlButton
          onClick={onPrint}
          label="Imprimir"
          width="w-20"
        />
        <ViewControlButton
          onClick={() => {
            setView("week");
          }}
          label="Semana"
          width="w-20"
        />
        <ViewControlButton
          onClick={() => setView("day")}
          label="Dia"
          width="w-20"
        />
        <ViewControlButton
          onClick={() => {
            setView("list");
          }}
          label="Lista"
          width="w-20"
        />
      </div>
    </div>
  );
};
