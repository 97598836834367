import { Modal } from "antd";
import ModalContainer from "./ModalContainer";
import { TabNavigationContext } from "../../Shared/contexts/TabNavigationContext";
import { useContext } from "react";
import {
  CalendarAppointmentConsumer,
  CalendarAppointmentProvider,
} from "../../Shared/contexts/CalendarAppointmentContext";

interface TicketModalProps {
  showTicketModal: boolean;
  setShowTicketModal: (state: boolean) => void;
  ticketId: number;
}

export const TicketModal: React.FC<TicketModalProps> = ({
  showTicketModal,
  setShowTicketModal,
  ticketId,
}) => {
  const { setActiveTab } = useContext(TabNavigationContext);
  const style = {
    padding: 15,
    height: "90vh",
  };

  const handleCancel = () => {
    setActiveTab?.("1");
    setShowTicketModal(false);
  };
  return (
    <CalendarAppointmentProvider>
      <CalendarAppointmentConsumer>
        {({ onPopClose }: any) => (
          <Modal
            className="relative"
            open={showTicketModal}
            onCancel={() => {
              onPopClose();
              handleCancel();
            }}
            centered
            width="95%"
            footer={null}
            bodyStyle={style}
          >
            <ModalContainer ticketId={ticketId} />
          </Modal>
        )}
      </CalendarAppointmentConsumer>
    </CalendarAppointmentProvider>
  );
};
