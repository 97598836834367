import { useSelector } from "react-redux";
import { AuthenticationState } from "../interfaces/users";
import { customGreetings } from "../utils";

function HomePage() {
  const { user } = useSelector((state: AuthenticationState) => state);
  return (
    <>
      <h1>ALIANÇA Móveis - ERP</h1>
      {user && customGreetings(user.fullName)} seja bem vindo.
      <div className="flex justify-center">
        <img className="opacity-10" src="/logoBig.png" alt="logo" />
      </div>
    </>
  );
}

export default HomePage;
