import { format, subDays } from "date-fns";
import { api } from "../../config";
import { toast } from "react-toastify";

export const updateDatabaseTickets = async (
  token: string,
  type: "checklist" | "measuring"
) => {
  try {
    await api(token).post(`tickets/${type}/fetch-external`, {
      date: format(subDays(new Date(), 30), "yyyyMMdd"),
    });
  } catch (error) {
    toast.error(
      "Ocorreu um erro ao recuperar os tickets, por favor tente novamente mais tarde"
    );
  }
};

export const formatCurrency = (value: number) => {
  const stringValue = value.toFixed(2).replace(/\D+/, "");

  if (stringValue.length > 8) {
    return stringValue.replace(
      /(\d{1,3})(\d{3})(\d{3})(\d{2})/g,
      "R$ $1.$2.$3,$4"
    );
  } else if (stringValue.length > 5) {
    return stringValue.replace(/(\d{1,3})(\d{3})(\d{2})/g, "R$ $1.$2,$3");
  } else {
    return stringValue.replace(/(\d{1,3})(\d{2})/g, "R$ $1,$2");
  }
};

export const importExternalTicketByExternalId = async (
  token: string,
  externalId: string,
  type: "checklist" | "measuring",
  handleClose: () => void
) => {
  try {
    const importedTickets = await api(token).get(
      `/tickets/${type}/import-external/${externalId}`
    );
    toast.success("Ticket importado com sucesso!");
    handleClose();
    return importedTickets;
  } catch (error: any) {
    toast.error(error.response?.data?.error);
  }
};

export const duplicateFieldReportTicket = async (
  fieldReportId: number,
  token: string
) => {
  try {
    await api(token).post(`/tickets/measuring/duplicate/${fieldReportId}`, {});
    toast.success("Ticket duplicado com sucesso!");
  } catch (error: any) {
    toast.error(error.response?.data?.error);
  }
};
