import { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Popconfirm, Table } from "antd";
import { DeleteOutlined, LockOutlined, EditOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { api } from "../../config";
import { UserModal } from "./UserModal";
import { deleteUser } from "../../utils";
import { PermissionsModal } from "./PermissionsModal";

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: any) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (editing) {
      //@ts-ignore
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    alert(1);
  };
  const save = async () => {
    alert(2);
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
export const Users = () => {
  const { token } = useSelector((state: AuthenticationState) => state);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [user, setUser] = useState<number | null>(null);
  const { data, isFetching, refetch } = useQuery("users", () =>
    api(token).get("/users")
  );
  const handleDelete = async (userId: number) => {
    await deleteUser(token, userId);
    refetch();
  };

  const handleOnOpen = (userSent: any, type?: "permissions" | "info") => {
    setUser(userSent);
    if (type === "info") {
      setShowEditModal(true);
    } else if (type === "permissions") {
      setShowPermissionsModal(true);
    }
  };

  const defaultColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "nome",
      dataIndex: "fullName",
      width: "40%",
      editable: true,
    },
    {
      title: "email",
      dataIndex: "email",
    },
    {
      title: "ações",
      dataIndex: "operation",
      render: (_: any, record: any) =>
        data.length >= 1 ? (
          <>
            <Popconfirm
              title="Deseja realmente apagar?"
              onConfirm={() => handleDelete(record.id)}
            >
              <Button
                className="m-1 p-1 mt-2"
                size="small"
                danger
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
            <Button
              className="m-1 p-2 mt-2 mr-5"
              size="small"
              onClick={() => handleOnOpen(record, "info")}
              icon={<EditOutlined />}
            />
            <Button
              className="m-2 p-2 mr-5"
              size="small"
              icon={<LockOutlined />}
              onClick={() => handleOnOpen(record, "permissions")}
            />
          </>
        ) : null,
    },
  ];
  const components = {
    body: {
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });
  return (
    <div>
      <PermissionsModal
        isOpen={showPermissionsModal}
        refetch={refetch}
        onClose={() => setShowPermissionsModal(false)}
        user={user}
      />
      <UserModal
        user={user}
        refetch={refetch}
        showEditModal={showEditModal}
        onClose={() => setShowEditModal(false)}
      />
      <Button
        onClick={() => handleOnOpen(undefined, "info")}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Adicionar Usuário
      </Button>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        loading={isFetching}
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};
export default Users;
