import { ReactNode, createContext } from "react";
import { TicketViewModel } from "../../../interfaces";

interface TicketContextInterface {
  ticket: TicketViewModel;
}

interface Props {
  children: ReactNode;
  ticket: TicketViewModel;
}

export const TicketContext = createContext({} as TicketContextInterface);

const TicketProvider = ({ children, ticket }: Props) => {
  return (
    <TicketContext.Provider value={{ ticket }}>
      {children}
    </TicketContext.Provider>
  );
};

export default TicketProvider;
