import { useState } from "react";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { toast } from "react-toastify";
import { updateUserInfo } from "../../utils";
import { Card, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { FormFooter } from "./FormFooter";

interface PasswordFormProps {
  onFormChange?: ({ target }: any) => void;
  shouldDisplayFooter?: boolean;
  className?: string;
  controllerFormData?: any
}

export const PasswordForm = ({
  shouldDisplayFooter,
  onFormChange,
  className = "w-1/2",
  controllerFormData
}: PasswordFormProps) => {
  const { user, token } = useSelector((state: AuthenticationState) => state);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const handleOnFormChange = ({ target }: any) => {
    if (onFormChange) {
      return onFormChange({ target });
    }
    const { id, value } = target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const errors = [];
    if (formData.password.length < 6) {
      errors.push("A senha precisa conter pelo menos 6 caracteres");
    }
    if (formData.confirmPassword !== formData.password) {
      errors.push("A senha digitada não coincide com a confirmação de senha");
    }

    if (errors.length) {
      return errors.forEach((error) => {
        setLoading(false);
        return toast.error(error);
      });
    }

    await updateUserInfo(token, user?.id ?? 0, { password: formData.password });

    setLoading(false);
  };

  return (
    <Card className={className} title="Alteração de senha de usuário">
      <p>Nova senha:</p>
      <Input.Password
        autoComplete="false"
        id="password"
        onChange={handleOnFormChange}
        value={controllerFormData?.password ?? formData.password}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <p>Confirme a nova senha</p>
      <Input.Password
        autoComplete="false"
        id="confirmPassword"
        onChange={handleOnFormChange}
        value={controllerFormData?.confirmPassword ?? formData.confirmPassword}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />

      {shouldDisplayFooter && (
        <FormFooter
          loading={loading}
          onClear={() =>
            setFormData({
              password: "",
              confirmPassword: "",
            })
          }
          onSubmit={handleSubmit}
        />
      )}
    </Card>
  );
};
