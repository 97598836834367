import { Input } from "antd";
import { ChangeEventHandler, ReactNode } from "react";
import { DescriptionItem } from "./DescriptionItem";

interface DescriptionFormInputProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  label?: string;
  readOnly?: boolean;
  id?: string;
  editing?: boolean;
  labelClass?: string;
  children?: ReactNode;
}

export const DescriptionFormInput = ({
  onChange,
  value,
  label,
  readOnly = false,
  id,
  editing = false,
  labelClass = "",
  children
}: DescriptionFormInputProps) => {
  return (
    <>
      {editing ? (
        <div className="flex gap-2 mt">
          <div className="mt-1">
            <label className="text-[#8C8C8C]">{label}</label>
          </div>
          <Input
            id={id}
            onChange={onChange}
            readOnly={readOnly}
            type="text"
            value={value}
          />
        </div>
      ) : (
        <DescriptionItem label={label} className={labelClass}>
          {children ? (children) : value}
        </DescriptionItem>
      )}
    </>
  );
};
