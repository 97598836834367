import { Reports } from "../modules/Reports/Reports";

export const ReportsPage = () => {
  return (
    <>
      <h1>Relatórios</h1>
      <Reports />
    </>
  );
};
