import axios from "axios";
import { actions } from "./actions";
import { toast } from "react-toastify";

export const authMiddleware =
  (storeAPI: any) => (next: any) => (action: any) => {
    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    const previousToken = storeAPI.getState().token;
    if (action.type === actions.LOGIN_STARTED) {
      storeAPI.dispatch({
        type: actions.START_LOADING,
      });
      axios
        .post(`${apiBaseURL}/api/users/auth`, action.payload)
        .then(({ data: { data } }) => {
          toast.success("Login realizado com sucesso, redirecionando...");
          storeAPI.dispatch({
            type: actions.LOGIN_SUCCESS,
            payload: data,
          });
        })
        .catch((err: any) => {
          toast.error("Email ou senha incorretos");
          storeAPI.dispatch({
            type: actions.LOGIN_FAILED,
          });
        });
      next(action);
    } else if (action.type === actions.UPDATE_USER) {
      axios
        .get(`${apiBaseURL}/api/user/${action.payload.userId}`)
        .then(({ data: { data } }) => {
          storeAPI.dispatch({
            type: actions.USER_UPDATED,
            payload: data,
            token: previousToken,
          });
        })
        .catch((err: any) => {
          toast.error(
            "Ocorreu um erro ao recuperar as informações do servidor"
          );
        });
      next(action);
    } else {
      return next(action);
    }
  };
