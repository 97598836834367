import { AuthenticationState } from "../../../../interfaces/users";
import { actions } from "../actions";

export const initialState: AuthenticationState = {
  isLoggedIn: false,
  token: "",
  user: undefined,
  loading: false,
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.START_LOADING:
      return { ...initialState, loading: true };
    case actions.LOGIN_STARTED:
      return { ...initialState, loading: true };
    case actions.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoggedIn: true,
        token: action.payload.token,
        user: action.payload,
        loading: false,
      });
    case actions.LOGIN_FAILED:
      return initialState;
    case actions.LOGOUT:
      return initialState;
    case actions.USER_UPDATED:
      return Object.assign({}, state, {
        isLoggedIn: true,
        token: action.token,
        user: action.payload,
        loading: false,
      });
  }
  return initialState;
};
