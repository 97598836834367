const List = ({ children }: any) => {
    return <li className="list-disc">{children}</li>;
};

export const PrivacyPolicy = () => {
    return (
        <>
            <div className="max-w-[900px] mx-auto text-justify">
                <h1>
                    Politica de Privacidade
                </h1>
                <p>
                    A sua privacidade é importante para nós. É política da CODR Software Ltda.
                    respeitar a sua privacidade em relação a qualquer informação sua que
                    possamos coletar no site CODR Software Ltda., e outros sites que possuímos e
                    operamos. Solicitamos informações pessoais apenas quando realmente
                    precisamos delas para lhe fornecer um serviço.
                    <br />
                    <br />
                    Fazemo-lo por meios justos e legais, com o seu conhecimento e
                    consentimento. Também informamos por que estamos coletando e como será
                    usado.
                    <br />
                    <br /> Apenas retemos as informações coletadas pelo tempo necessário
                    para fornecer o serviço solicitado. Quando armazenamos dados,
                    protegemos dentro de meios comercialmente aceitáveis para evitar
                    perdas e roubos, bem como acesso, divulgação, cópia, uso ou
                    modificação não autorizados.
                    <br />
                    <br /> O nosso site pode ter links para sites externos que não são
                    operados por nós. Esteja ciente de que não temos controle sobre o
                    conteúdo e práticas desses sites e não podemos aceitar
                    responsabilidade por suas respectivas políticas de privacidade.
                    <br />
                    <br /> Você é livre para recusar a nossa solicitação de informações
                    pessoais, entendendo que talvez não possamos fornecer alguns dos
                    serviços desejados.
                    <br />
                    <br /> O uso continuado de nosso site será considerado como aceitação
                    de nossas práticas em torno de privacidade e informações pessoais. Se
                    você tiver alguma dúvida sobre como lidamos com dados do usuário e
                    informações pessoais, entre em contacto connosco.
                </p>
                <div className="max-w-[800px] mx-auto my-5">
                    <ul>
                        <List>
                            O serviço Google AdSense que usamos para veicular publicidade usa
                            um cookie DoubleClick para veicular anúncios mais relevantes em
                            toda a Web e limitar o número de vezes que um determinado anúncio
                            é exibido para você.
                        </List>
                        <List>
                            Para mais informações sobre o Google AdSense, consulte as FAQs
                            oficiais sobre privacidade do Google AdSense.
                        </List>
                        <List>
                            Utilizamos anúncios para compensar os custos de funcionamento
                            deste site e fornecer financiamento para futuros desenvolvimentos.
                            Os cookies de publicidade comportamental usados por este site
                            foram projetados para garantir que você forneça os anúncios mais
                            relevantes sempre que possível, rastreando anonimamente seus
                            interesses e apresentando coisas semelhantes que possam ser do seu
                            interesse.
                        </List>
                        <List>
                            Vários parceiros anunciam em nosso nome e os cookies de
                            rastreamento de afiliados simplesmente nos permitem ver se nossos
                            clientes acessaram o site através de um dos sites de nossos
                            parceiros, para que possamos creditá-los adequadamente e, quando
                            aplicável, permitir que nossos parceiros afiliados ofereçam
                            qualquer promoção que pode fornecê-lo para fazer uma compra.
                        </List>
                    </ul>
                </div>
                <p className="my-5">Compromisso do Usuário</p>
                <p>
                    O usuário se compromete a fazer uso adequado dos conteúdos e da
                    informação que a CODR Software Ltda. oferece no site e com caráter
                    enunciativo, mas não limitativo:
                </p>
                <div className="max-w-[800px] mx-auto my-5">
                    <List>
                        A) Não se envolver em atividades que sejam ilegais ou contrárias à
                        boa fé a à ordem pública;
                    </List>
                    <List>
                        B) Não difundir propaganda ou conteúdo de natureza racista,
                        xenofóbica, pix bet365 ou azar, qualquer tipo de pornografia ilegal,
                        de apologia ao terrorismo ou contra os direitos humanos;
                    </List>
                    <List>
                        C) Não causar danos aos sistemas físicos (hardwares) e lógicos
                        (softwares) da CODR Software Ltda., de seus fornecedores ou terceiros, para
                        introduzir ou disseminar vírus informáticos ou quaisquer outros
                        sistemas de hardware ou software que sejam capazes de causar danos
                        anteriormente mencionados.
                    </List>
                </div>
                <p className="my-5"> Mais informações</p>
                <p>
                    Esperemos que esteja esclarecido e, como mencionado anteriormente, se
                    houver algo que você não tem certeza se precisa ou não, geralmente é
                    mais seguro deixar os cookies ativados, caso interaja com um dos
                    recursos que você usa em nosso site.
                </p>
            </div>
        </>
    )
}