import { renderDateFormated } from "../../../../../utils";

import { CalendarOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { TicketContext } from "../../../../Shared/contexts/TicketContext";
import { useContext } from "react";

const renderItem = (
  title: string,
  details: string | undefined,
  date: string
) => {
  return (
    <div className="flex justify-between border-b">
      <div className="text-md flex flex-row items-end ">
        {title}
        <p className="text-xs text-gray-500 ml-2 mb-1">{details}</p>
      </div>

      <p className="text-xs text-gray-500 py-0 my-0">
        <CalendarOutlined className=" mt-3.5 pr-1" />
        {date}
      </p>
    </div>
  );
};

function Schedules() {
  const { ticket } = useContext(TicketContext) as any;
  const scheduling = ticket.events;

  return (
    <div className="mb-3">
      <Card title="Agendamentos" size="small" headStyle={{ color: "#1677FF" }}>
        <div className="mb-0 overflow-auto pr-2" style={{ height: "15vh" }}>
          {scheduling.map((event: any) => (
            <>
              {renderItem(
                event.agenda.title,
                event.description,
                renderDateFormated(event.start)
              )}
            </>
          ))}
        </div>
      </Card>
    </div>
  );
}

export default Schedules;
