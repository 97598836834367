import { createContext, useState } from "react";

interface TabNavigationContextValues {
  activeTab?: string;
  setActiveTab?(tab: string): void;
}

export const TabNavigationContext = createContext<TabNavigationContextValues>({})

export const TabNavigationContextProvider: any = ({ children, ticketId }: any) => {
    const [activeTab, setActiveTab] = useState<string>("1");

    return (
      <TabNavigationContext.Provider
        value={{ activeTab, setActiveTab }}
      >
        {children}
      </TabNavigationContext.Provider>
    );
}