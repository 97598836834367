import { UserInterface } from "../../interfaces/users";

export enum ModulePermission {
  ADMIN = "ADMIN",
  USER = "USER",
  VIEW = "VIEW",
  NONE = "NONE",
}

export interface ModulesUserHasPermission {
  checklist?: ModulePermission;
  measuring?: ModulePermission;
  calendar?: ModulePermission;
  users?: ModulePermission;
}

export const userHasPermission = (
  user: any,
  modules: ModulesUserHasPermission
) => {
  const userModules = user.modules;
  let hasPermission = false;
  userModules.forEach((module: any) => {
    Object.entries(modules).forEach(([key, entry]) => {
      if (module.moduleName.toLowerCase() === key) {
        if (module.permission === entry) {
          hasPermission = true;
        } else {
          hasPermission = false;
        }
      }
    });
  });

  return hasPermission;
};

type PermissionsModules = "checklist" | "measuring" | "users" | "calendar" | "app";

export const hasMinimumPermissions = (
  type: PermissionsModules,
  user?: Partial<UserInterface>
) => {
  return (
    userHasPermission(user, {
      [type]: ModulePermission.ADMIN,
    }) ||
    userHasPermission(user, {
      [type]: ModulePermission.USER,
    })
  );
};

export const isSharedComponentDisabled = (
  type: "checklist" | "measuring" = "checklist",
  user?: Partial<UserInterface>,
  disabled = false
) => {
  return (
    (type === "measuring" && disabled) || !hasMinimumPermissions(type, user)
  );
};

export const hasNoPermission = (
  type: PermissionsModules,
  user?: Partial<UserInterface>
) =>
  !userHasPermission(user, {
    [type]: ModulePermission.NONE,
  });


export const formatPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber?.length === 11) {
    return phoneNumber.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, "($1) $2-$3-$4")
  } else if (phoneNumber?.length === 10) {
    return phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3")
  } else if (phoneNumber?.length === 9) {
    return phoneNumber.replace(/(\d{1})(\d{4})(\d{4})/g, "$1-$2-$3")
  } else if (phoneNumber?.length === 8) {
    return phoneNumber.replace(/(\d{4})(\d{4})/g, "$1-$2")
  } else {
    return phoneNumber
  }
}