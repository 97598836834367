import { Button, DatePicker, Input, Modal, Popconfirm, Select } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { noRetry } from "../../../../utils";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../../interfaces/users";
import { api } from "../../../../config";
import { add, isAfter, isBefore, isEqual, startOfHour, sub } from "date-fns";

interface NewEventModalProps {
  isOpen?: boolean;
  onSubmit: () => any;
  onClose: () => void;
  loading: boolean;
  formData: any;
  onChange: (
    id: string | string[],
    value: string | Date | string[] | Date[]
  ) => void;
  editing?: number | false;
  onDelete?: () => void;
}

export const NewEventModal: React.FC<NewEventModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
  loading,
  formData,
  onChange,
  onDelete,
  editing,
}) => {
  const { token } = useSelector((state: AuthenticationState) => state);
  const { data }: any = useQuery(
    ["agenda"],
    () => api(token).get(`agenda`),
    noRetry
  );

  const handleDateChange = (
    currentId: string,
    compareId: string,
    currentDate: dayjs.Dayjs,
    compareDate: string,
    compareFunction: (date1: Date, date2: Date) => boolean,
    modifyDate: (date: Date | number, duration: Duration) => Date
  ) => {
    if (
      currentDate &&
      (compareFunction(
        new Date(currentDate?.toDate()),
        new Date(compareDate)
      ) ||
        isEqual(new Date(currentDate?.toDate()), new Date(compareDate)))
    ) {
      return onChange(
        [currentId, compareId],
        [
          currentDate?.toDate() ?? new Date(startOfHour(new Date())),
          modifyDate(currentDate?.toDate(), { hours: 1 }) ??
            new Date(startOfHour(new Date())),
        ]
      );
    }
    onChange(
      currentId,
      currentDate?.toDate() ?? new Date(startOfHour(new Date()))
    );
  };

  return (
    <Modal
      className="relative"
      title="Confirmação"
      open={isOpen}
      onOk={onSubmit}
      confirmLoading={loading}
      onCancel={onClose}
    >
      <div className="absolute top-4 right-16">
        {formData.ticketId && (
          <a
            className="font-bold"
            href={`/checklist?id=${formData.ticketId}`}
            target="_blank"
            rel="noreferrer"
          >
            Abrir Ticket
          </a>
        )}
      </div>
      <p>
        Agendamento em nome de:{" "}
        <Select
          placeholder="Selecionar"
          value={formData.agendaId}
          style={{ width: 200 }}
          onChange={(e) => onChange("agendaId", e)}
          options={data?.map((item: any) => {
            return {
              value: item.id,
              label: item.title,
            };
          })}
        />
      </p>
      <p>Descrição do evento:</p>
      <Input
        value={formData.description}
        onChange={(e) => onChange("description", e.target.value)}
        placeholder="Opcional"
      />
      <p>Observação:</p>
      <Input
        value={formData.notes}
        onChange={(e) => onChange("notes", e.target.value)}
        placeholder="Opcional"
      />
      <div className="flex justify-between">
        <div>
          <p>De:</p>
          <DatePicker
            format="DD/MM/YY HH:mm"
            locale={locale}
            value={dayjs(formData.start)}
            id="start"
            showTime
            showMinute={false}
            showSecond={false}
            disabled={!editing}
            onChange={(date) =>
              date &&
              handleDateChange("start", "end", date, formData.end, isAfter, add)
            }
          />
        </div>
        <div>
          <p>Até:</p>
          <DatePicker
            format="DD/MM/YY HH:mm"
            locale={locale}
            value={dayjs(formData.end)}
            id="end"
            showTime
            showMinute={false}
            showSecond={false}
            onChange={(date) =>
              date &&
              handleDateChange(
                "end",
                "start",
                date,
                formData.start,
                isBefore,
                sub
              )
            }
          />
        </div>
      </div>
      {editing && (
        <div className="absolute bottom-4 left-3">
          <Popconfirm
            title="Deseja remover este agendamento?"
            cancelText="Cancelar"
            okText="Excluir"
            onConfirm={onDelete}
          >
            <Button type="link" color="red">
              Excluir
            </Button>
          </Popconfirm>
        </div>
      )}
    </Modal>
  );
};
