import { CalendarOutlined, FileOutlined } from "@ant-design/icons";
import { capitalizeWords, renderDateFormated } from "../../utils";

function CardDescription({
  type,
  scheduleDate,
  client,
  seller,
  neighbor,
  city,
  state,
  hasAttachments,
  onClick,
}: any) {
  const showTime = type === "checklist" ? true : false;
  return (
    <>
      <div>
        <CalendarOutlined />{" "}
        {scheduleDate
          ? renderDateFormated(scheduleDate, false, showTime)
          : "N/A"}
      </div>
      {type === "checklist" ? (
        <>
          <div>Cliente: {capitalizeWords(client)}</div>
          <div>Vendedor: {capitalizeWords(seller)}</div>
        </>
      ) : (
        <>
          {hasAttachments && (
            <div className="w-56 relative">
              <div className=" absolute font-bold italic text-xs bottom-3 right-0">
                <FileOutlined />
              </div>
            </div>
          )}
          <div>Bairro: {neighbor ? capitalizeWords(neighbor) : "(Não informado)"}</div>
          <div>
            Cidade: {city ? `${capitalizeWords(city)}-${state?.toUpperCase()}` : '(Não informado)'}
          </div>
        </>
      )}
    </>
  );
}

export default CardDescription;
