import { Button,  Popconfirm, Input } from "antd";
const text = "Qual o email?";

interface Props {
  loading?: boolean;
  setDestination: (value: string) => void
  onConfirm: () => void
}

export const Email = ({ setDestination, onConfirm, loading }: Props) => {
  return (
    <>
      <Popconfirm
        placement="topLeft"
        title={text}
        
        description={
          <Input
            onChange={(e) => setDestination(e.target.value)}
            placeholder="Digite o email"
            type="email"
          />
        }

        okText="Enviar"
        cancelText="Cancelar"
        onConfirm={onConfirm}
      >
        <Button loading={loading} type="primary"> Email</Button>
      </Popconfirm>
    </>
  );
};

export default Email;
