import LoginForm from "./LoginForm";
import Logo from "../Shared/Logo";

function Login() {
  return (
    <div className=" flex h-screen w-full items-center justify-center bg-slate-100">
      <div className="w-96 ">
        <Logo />

        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
