import { Layout } from "antd";
import MainMenu from "../Menu";
import { Outlet } from "react-router-dom";
import ProtectedHandler from "../../authentication/Components/ProtectedComponent";
import ButtonHelp from "../Shared/HelpButton";

const { Content, Footer } = Layout;

function MainLayout() {
  return (
    <Layout className="min-h-screen">
      <MainMenu />
      <Layout className="site-layout">
        <Content className="mt-5 mr-4">
          <ProtectedHandler>
            <ButtonHelp />
            <div className="bg-slate-100 pl-6 pb-5">
              <Outlet />
            </div>
          </ProtectedHandler>
        </Content>
        <Footer className="text-center pt-0">
          Aliança Móveis ©2023 Criado por{" "}
          <a href="http://codr.software" target="_blank" rel="noreferrer">
            CODR Software
          </a>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
