
interface DescriptionItemProps {
  className?: string;
  label?: string;
  children: React.ReactNode
}

export const DescriptionItem = ({
  className = "",
  label,
  children,
}: DescriptionItemProps) => {
  return (
    <div className={`flex gap-2 ${className}`}>
      <div className="h-8">
        <label className="text-[#8C8C8C]">{label}</label>
      </div>
      <div className="px-3">{children}</div>
    </div>
  );
};