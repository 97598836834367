import { useSelector } from "react-redux";
import { Calendar } from "../modules/Calendar/Calendar";
import { AuthenticationState } from "../interfaces/users";
import { hasNoPermission } from "../utils/helpers/userHelpers";
import { useMemo } from "react";

function Calendario() {
  const { user } = useSelector((state: AuthenticationState) => state);
  const hasPermission = useMemo(
    () => hasNoPermission("calendar", user),
    [user]
  );
  return (
    <>
      {hasPermission ? (
        <Calendar />
      ) : (
        <div>O seu usuário não possui permissão para acessar este conteúdo</div>
      )}
    </>
  );
}

export default Calendario;
