export const actions = {
  LOGIN_STARTED: "LOGIN_STARTED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGOUT: "LOGOUT",
  UPDATE_USER: "UPDATE_USER",
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  USER_UPDATED: "USER_UPDATED",
  USER_UPDATE_FAILED: "USER_UPDATE_FAILED",
};
