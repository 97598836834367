import { Typography, Badge, Progress, Skeleton, Button } from "antd";
import { SidePanel } from "../../Shared/Components/SidePanel";
import { renderPercentage, measuringStatusTitles } from "../../../utils";
import { api } from "../../../config";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../interfaces/users";
import TicketMeasure from "../../Measuring/TicketModal/TicketMeasure";
import { toast } from "react-toastify";

const { Title } = Typography;

function ModalContainer({ ticketId }: any) {
  const { token } = useSelector((state: AuthenticationState) => state);
  const { isFetching, data, isError, refetch }: any = useQuery(
    ["tickets/measuring"],
    () => api(token).get(`ticket/measuring/${ticketId}`)
  );
  const [editTicketId, setEditTicketId] = useState(false);
  const [formTicketId, setFormTicketId] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (data?.ticketId) {
      setFormTicketId(data.ticketId);
    }
    setEditTicketId(false);
  }, [data, ticketId]);

  const handleEditTicketIdToggle = () => {
    setEditTicketId(!editTicketId);
  };

  const handleSubmitTicketIdChange = async () => {
    try {
      if (!formTicketId) return toast.error("Id inválido");
      await api(token).put(`/ticket/measuring/${ticketId}`, {
        ticketId: formTicketId,
      });
      handleEditTicketIdToggle();
      refetch();
      toast.success("Relatório vinculado com sucesso!");
    } catch (error) {
      toast.error("Ocorreu um erro ao vincular o ID do ticket");
    }
  };

  useEffect(() => {
    if (!isError) {
      refetch();
    }
  }, [isError, refetch, ticketId]);

  if (isFetching) return <Skeleton active />;
  if (isError)
    return (
      <>
        <Title className=" border-b-2  border-slate-100 pb-1 ">
          Erro durante o processo de buscar ticket
        </Title>
      </>
    );

  return (
    <>
      <Badge.Ribbon
        className="px-8 mt-12 mx-2"
        text={measuringStatusTitles[data?.status ?? 1]}
        color={data?.status === 5 ? "red" : "blue"}
      />
      <div className="flex justify-between">
        <Title className=" border-b-2 border-slate-100 pb-1 ">
          {data.title}
        </Title>

        <div className="mr-52 flex gap-1 items-center justify-center">
          <p className="font-bold">Ticket Checklist: </p>
          {editTicketId ? (
            <input
              value={formTicketId}
              onChange={(e) =>
                setFormTicketId(Number(e.target.value.replace(/\D+/g, "")))
              }
              className="h-6 mr-1 w-10"
              type="text"
            />
          ) : (
            <p> {data.ticketId}</p>
          )}
          <Button
            onClick={
              editTicketId
                ? handleSubmitTicketIdChange
                : handleEditTicketIdToggle
            }
          >
            {editTicketId ? "Salvar" : "Editar"}
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/5">
          <SidePanel
            status={data?.status}
            ticketId={data.id}
            type="measuring"
            ownerId={data.ownerId}
          />
        </div>
        <div className="w-4/5 h-[60vh] overflow-y-scroll">
          <TicketMeasure ticket={data} />
        </div>
      </div>
      <div className="w-11/12 absolute bottom-3">
        <div className="w-full flex justify-center items-center">
          <p className="mr-5">Progresso:</p>
          <Progress
            className="mt-2"
            status={
              data?.status === 5 || data?.status === 6 ? "exception" : "active"
            }
            percent={renderPercentage(data?.status)}
          />
        </div>
      </div>
    </>
  );
}

export default ModalContainer;
