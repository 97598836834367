import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";

//import layout here...

function ProtectedHandler({ children, redirectPath = "/login" }: any): JSX.Element {
  const JSONserver = process.env.REACT_APP_API_REMOTE_SERVER !== "api";
  const { user } = useSelector((state) => state) as AuthenticationState;
  if (!JSONserver && !user) {
    return (
      <Navigate
        to={redirectPath}
        state={{
          prevPath: `${window.location.pathname}${window.location.search ?? ""}`,
        }}
        replace
      />
    );
  }
  return <div>{children}</div>;
}

export default ProtectedHandler;
