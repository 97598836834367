import { Form, Input, Radio, Typography } from "antd";

interface QuestionProps {
  general?: boolean;
  questionId: number;
  type: string;
  header?: string;
  question: string;
  options: Array<any>;
  otherOption: boolean;
  required: boolean;
  disabled?: boolean;
}

const Question = ({
  general,
  questionId,
  type,
  header,
  question,
  options,
  otherOption,
  required,
  disabled,
}: QuestionProps) => {
  if (type === "text")
    return (
      <Form.Item name={questionId} label={question} rules={[{ required }]}>
        <Input
          disabled={disabled}
          placeholder="Resposta..."
          className="w-80 mt-3"
        />
      </Form.Item>
    );

  return (
    <>
      {header && (
        <Typography.Title
          type="secondary"
          level={5}
          style={{ borderBottom: "1px solid #d9d9d9" }}
        >
          {header?.toLocaleUpperCase()}
        </Typography.Title>
      )}
      <Form.Item name={questionId} label={question} rules={[{ required }]}>
        <Radio.Group disabled={disabled} className="mt-3">
          <div className="flex flex-col">
            {JSON.parse(options as any).map((option: any) => (
              <Radio disabled={disabled} key={option.text} value={option.text}>
                {option.text}
              </Radio>
            ))}
            {otherOption && (
              <Form.Item name={`ADD@@${questionId}`} noStyle>
                <Input
                  disabled={disabled}
                  placeholder="Outra resposta..."
                  className="w-80 mt-3"
                />
              </Form.Item>
            )}
          </div>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default Question;
