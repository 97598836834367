import { Button, Input, Modal } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { api } from "../../config";
import { toast } from "react-toastify";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";

interface ForgotPasswordProps {
  isOpen: boolean;
  onClose: () => void;
}

enum Steps {
  AccountIdentify = "AccountIdentify",
  CodeConfirmation = "CodeConfirmation",
  SetNewPassword = "SetNewPassword",
}

interface ForgotPasswordFooterProps {
  onClick: () => void;
  onCancel: () => void;
  loading?: boolean;
}

export const ForgotPasswordFooter: React.FC<ForgotPasswordFooterProps> = ({
  onClick,
  onCancel,
  loading,
}) => {
  return (
    <div className="w-full flex justify-end gap-4">
      <Button className="mt-4" onClick={onCancel}>
        Cancelar
      </Button>
      <Button
        loading={loading}
        className="mt-4"
        type="primary"
        onClick={onClick}
      >
        Próximo
      </Button>
    </div>
  );
};

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  isOpen,
  onClose,
}) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(Steps.AccountIdentify);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userId, setUserId] = useState(0);
  const { token } = useSelector((state: AuthenticationState) => state);

  const handleCancel = async () => {
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setCurrentStep(Steps.AccountIdentify);
    setLoading(false);
    setPassword("");
    setConfirmPassword("");
    setCode("");
    setUserId(0);
    setEmail("");
    onClose();
  };

  const handleSubmit = async () => {
    let errors = [];
    if (password.length < 6)
      errors.push("Senha deve conter mais do que 6 caracteres");
    if (password !== confirmPassword)
      errors.push(
        "A confirmação de senha deve coincidir com a senha informada"
      );

    if (errors.length) return errors.forEach((error) => toast.error(error));
    setLoading(true);
    try {
      await api(token).post("users/forgotPassword/newPassword", {
        email,
        code,
        password,
        userId,
      });
      toast.success(
        "Nova senha configurada, por favor faça o login para acessar a plataforma"
      );
      handleCloseModal();
    } catch (error: any) {
      toast.error(error.error);
      setLoading(false);
    }
  };
  const handleCheckEmail = async () => {
    setLoading(true);
    try {
      const response = await api(token).post("users/forgotPassword/email", {
        email,
      });
      toast.success(response.data);
      setCurrentStep(Steps.CodeConfirmation);
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.error);
      setLoading(false);
    }
  };

  const handleConfirmSecurityCode = async () => {
    setLoading(true);
    try {
      const response = await api(token).post("users/forgotPassword/code", {
        email,
        code,
      });
      toast.success(
        "Código confirmado, por favor preencha os campos da próxima etapa para configurar uma nova senha"
      );
      setUserId(response.userId);
      setCurrentStep(Steps.SetNewPassword);
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.error);
      setLoading(false);
    }
  };

  return (
    <Modal
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      open={isOpen}
    >
      <h4>Recuperação de senha</h4>
      {currentStep === Steps.AccountIdentify && (
        <Content>
          <p>Digite seu email de cadastro:</p>
          <Input
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ForgotPasswordFooter
            loading={loading}
            onCancel={handleCancel}
            onClick={handleCheckEmail}
          />
        </Content>
      )}
      {currentStep === Steps.CodeConfirmation && (
        <Content>
          <p>Digite o código enviado para o seu e-mail:</p>
          <Input
            name="securityCode"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <ForgotPasswordFooter
            loading={loading}
            onCancel={handleCancel}
            onClick={handleConfirmSecurityCode}
          />
        </Content>
      )}

      {currentStep === Steps.SetNewPassword && (
        <Content>
          <p>Digite sua nova senha:</p>
          <Input.Password
            autoComplete="false"
            id="password"
            placeholder="Senha"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />

          <p>Confirme a sua nova senha:</p>
          <Input.Password
            autoComplete="false"
            id="confirmPassword"
            value={confirmPassword}
            placeholder="Confirmação de Senha"
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <ForgotPasswordFooter
            loading={loading}
            onCancel={handleCancel}
            onClick={handleSubmit}
          />
        </Content>
      )}
    </Modal>
  );
};
