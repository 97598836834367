import App from "../App";
import { createBrowserRouter } from "react-router-dom";

import HomePage from "../pages/HomePage";
import ChecklistPage from "../pages/Checklist";
import { Measuring } from "./Measuring";
import Calendario from "../pages/Calendario";
import Error404 from "../pages/Error404";
import LoginPage from "../pages/Login";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "../authentication/services/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { UsersPage } from "./Users";
import { ProfilePage } from "./Profile";
import { ReportsPage } from "./Reports";
import { PrivacyPolicy } from "./PolicyProvacy";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error404 />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "checklist",
        element: <ChecklistPage />,
      },
      {
        path: "medicao",
        element: <Measuring />,
      },
      {
        path: "calendario",
        element: <Calendario />,
      },
      {
        path: "usuarios",
        element: <UsersPage />,
      },
      {
        path: "perfil",
        element: <ProfilePage />,
      },
      {
        path: "relatorios",
        element: <ReportsPage />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LoginPage />
        </PersistGate>
      </ReduxProvider>
    ),
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  }
]);
