import React, { useState } from "react";
import { MenuProps } from "antd";
import {
  ToolOutlined,
  FileDoneOutlined,
  HomeOutlined,
  PoweroffOutlined,
  HighlightOutlined,
  CalendarOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";

import { useNavigate } from "react-router-dom";
import Logo from "../Shared/Logo";
import { useDispatch } from "react-redux";
import { actions } from "../../authentication/services/redux/actions";

type MenuItem = Required<MenuProps>["items"][number];

const { Sider } = Layout;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Home", "/", <HomeOutlined />),
  getItem("Checklist", "/checklist", <FileDoneOutlined />),
  getItem("Medição", "/medicao", <HighlightOutlined />),
  getItem("Relatórios", "/relatorios", <FileSearchOutlined />),
  getItem("Calendário", "/calendario", <CalendarOutlined />),
  getItem("Configurações", "sub1", <ToolOutlined />, [
    getItem("Meu Perfil", "/perfil"),
    getItem("Usuários", "usuarios"),
  ]),
  getItem("Sair", "logout", <PoweroffOutlined />),
];

function MainMenu() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const handleNavigation = (key: string) => {
    if (key === "logout") return dispatch({ type: actions.LOGOUT });
    navigation(key);
  };

  return (
    <Sider
      collapsible
      collapsed={menuCollapsed}
      onCollapse={(value) => setMenuCollapsed(value)}
    >
      <div className="flex justify-center mt-3 ">
        <Logo />
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={items}
        onClick={({ key }) => handleNavigation(key)}
      />
    </Sider>
  );
}

export default MainMenu;
