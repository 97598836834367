import { useState } from "react";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import Help from "../../pages/HelpPage";

const ButtonHelp = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="relative">
      <Button
        className="absolute right-0 bg-slate-100"
        shape="circle"
        type="dashed"
        onClick={() => setShow(!show)}
        icon={<QuestionCircleTwoTone />}
      />
      {show ? <Help onClose={setShow} /> : null}
    </div>
  );
};
export default ButtonHelp;
