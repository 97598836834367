import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { changeTicketStatus } from "../../../utils";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../interfaces/users";
import { useQueries } from "react-query";
import { api } from "../../../config";
import { isSharedComponentDisabled } from "../../../utils/helpers/userHelpers";
import { duplicateFieldReportTicket } from "../../../utils/helpers/ticketHelpers";

function ButtonMore({ ticketId, disabled = false, type = "measuring" }: any) {
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const [{ refetch: ticketRefetch }, { refetch: boardRefetch }] = useQueries([
    {
      queryKey: "tickets/measuring",
      queryFn: () => api(token).get(`ticket/measuring/${ticketId}`),
      refetchOnMount: false,
    },
    {
      queryKey: "tickets/measuring/board",
      queryFn: () => api(token).get(`tickets/measuring/board`),
      refetchOnMount: false,
    },
  ]);

  const handleOnClick = async (status: number) => {
    await changeTicketStatus(status, ticketId, token, "measuring");
    boardRefetch();
    ticketRefetch();
  };

  const handleDuplicate = async () => {
    if (
      window.confirm(
        "Tem certeza de que deseja duplicar este ticket de medição?"
      )
    ) {
      await duplicateFieldReportTicket(ticketId, token);
      boardRefetch();
    }
  };

  const items = [
    {
      key: "0",
      label: " Duplicar Ticket",
      onClick: () => handleDuplicate(),
    },
    {
      key: "1",
      label: " Reiniciar Ticket",
      onClick: () => handleOnClick(0),
    },
    {
      key: "2",
      label: "Cancelar",
      danger: true,
      onClick: () => handleOnClick(4),
    },
    {
      key: "3",
      label: "Em Obras",
      danger: true,
      onClick: () => handleOnClick(5),
    },
  ];
  return (
    <Space className="absolute right-0 top-2">
      <Dropdown
        disabled={isSharedComponentDisabled(type, user, disabled)}
        menu={{ items }}
        placement="bottomLeft"
      >
        <Button
          disabled={isSharedComponentDisabled(type, user, disabled)}
          size="small"
          shape="circle"
        >
          <MoreOutlined />
        </Button>
      </Dropdown>
    </Space>
  );
}
export default ButtonMore;
