import { formatDistanceStrict } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

interface CardTitleInterface {
  id: Number;
  onClick?: any;
  title: string;
  saleDate: string;
}

function CardTitle({ id, title, saleDate, onClick }: CardTitleInterface) {
  return (
    <div
      onClick={() => onClick(id)}
      className="cursor-pointer hover:opacity-75"
    >
      <div className="w-56 ">
        {title}
        <div className="font-bold italic text-xs text-sky-400 float-right ">
          {formatDistanceStrict(new Date(), new Date(saleDate), {
            locale: ptBrLocale, unit:"day"
          })}
        </div>
      </div>
    </div>
  );
}

export default CardTitle;
