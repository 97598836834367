import { Empty } from "antd";

export const EmptyState = () => {
  return (
    <div className=" p-16 pt-6 bg-slate-100 ">
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{ height: '40vh' }}
        description={<h3 className="text-gray-600">Não há ticket de medição atribuido à venda no momento</h3>}
      />
    </div>
  );
}