import { useEffect, useState } from "react";
import { Button, DatePicker, Input, Select, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EditTwoTone, SaveTwoTone } from "@ant-design/icons";
import { measuringStatusTitles } from "../../../utils";
import locale from "antd/es/date-picker/locale/pt_BR";
import { UseQueryResult, useQueries } from "react-query";
import { api } from "../../../config";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../interfaces/users";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { format } from "date-fns";
import {
  hasMinimumPermissions,
  isSharedComponentDisabled,
} from "../../../utils/helpers/userHelpers";

interface DataType {
  key: string;
  date: string;
  tags: string[];
  icon: object;
}

const defaultForm = {
  ownerId: "",
  title: "",
  dueDate: "",
};

function MainInformation({ ticket, disabled }: any) {
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { token, user } = useSelector((state: AuthenticationState) => state);

  const [
    { data: usersData, isFetching: usersIsFetching },
    { refetch: ticketRefetch },
    { refetch: boardRefetch },
  ] = useQueries<UseQueryResult[]>([
    { queryKey: "users", queryFn: () => api(token).get("/users") },
    {
      queryKey: "tickets/measuring",
      queryFn: () => api(token).get(`ticket/measuring/${ticket.id}`),
      refetchOnMount: false,
    },
    {
      queryKey: "tickets/measuring/board",
      queryFn: () => api(token).get(`tickets/measuring/board`),
      refetchOnMount: false,
    },
  ]);

  const [formData, setFormData] = useState(defaultForm);

  useEffect(() => {
    setFormData({
      ownerId: ticket.ownerId,
      title: ticket.title,
      dueDate: ticket.dueDate,
    });
  }, [ticket]);

  const handleOnFormChange = ({ target }: any) => {
    const { id, value } = target;
    setFormData({ ...formData, [id]: value });
  };

  const persistMeasureTicketInformation = async (
    token: string,
    ticketId: string,
    payload: any
  ) => {
    try {
      const updatedTicket = await api(token).put(
        `/ticket/measuring/${ticketId}`,
        payload
      );
      toast.success("Ticket atualizado com sucesso!");
      return updatedTicket;
    } catch (error) {
      toast.error("Ocorreu um erro durante a atualização do ticket");
    }
  };

  const saveDetails = async () => {
    setLoading(true);
    await persistMeasureTicketInformation(token, ticket.id, formData);
    ticketRefetch();
    boardRefetch();
    setEdit(false);
    setLoading(false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Responsável",
      dataIndex: "name",
      key: "name",
      render: () => (
        <Select
          placeholder="Selecionar"
          disabled={!edit || usersIsFetching}
          style={{ width: 200 }}
          onChange={(e) =>
            handleOnFormChange({ target: { id: "ownerId", value: e } })
          }
          id="ownerId"
          value={formData.ownerId}
          options={(usersData as any[])
            ?.filter((item) => hasMinimumPermissions("app", item))
            ?.map((item: any) => {
              return {
                value: item.id,
                label: item.fullName,
              };
            })}
        />
      ),
    },
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
      render: (title) => (
        <Input
          value={formData.title}
          id="title"
          placeholder={title}
          disabled={!edit}
          onChange={handleOnFormChange}
        />
      ),
    },
    {
      title: "Data Limite",
      dataIndex: "deadline",
      key: "deadline",
      render: (date) => (
        <DatePicker
          id="dueDate"
          locale={locale}
          value={formData.dueDate ? dayjs(new Date(formData.dueDate)) : null}
          format="DD-MM-YYYY"
          disabled={!edit}
          showMinute={false}
          onChange={(value) =>
            handleOnFormChange({ target: { id: "dueDate", value } })
          }
          placeholder={date}
        />
      ),
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "ID Ticket",
      key: "externalId",
      dataIndex: "externalId",
      render: () => ticket.externalId,
    },
    {
      title: "ID Simp",
      key: "id",
      dataIndex: "id",
      render: () => ticket.id,
    },
    {
      title: "Situação",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = "blue";
            if (tag === "loser") {
              //TODO: CONDIÇÃO PARA TAG VERMELHA QUANDO TICKET CANCELADO.
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag?.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "icon",
      key: "icon",
      render: (icon) => icon,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={[
          {
            key: "1",
            date: format(new Date(ticket.createdAt), "dd/MM/yyyy"),
            tags: [measuringStatusTitles[ticket?.status]],
            icon: [
              <>
                <Button
                  type="link"
                  size={"middle"}
                  style={{ width: 32 }}
                  icon={edit ? <SaveTwoTone /> : <EditTwoTone />}
                  disabled={isSharedComponentDisabled(
                    "measuring",
                    user,
                    disabled
                  )}
                  className="p-1"
                  loading={loading}
                  onClick={() => (edit ? saveDetails() : setEdit(true))}
                />
              </>,
            ],
          },
        ]}
        pagination={false}
        className="mb-3"
      />
    </>
  );
}

export default MainInformation;
