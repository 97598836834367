import { useSelector } from "react-redux";
import { Board } from "../modules/Shared/Components/Board";
import { AuthenticationState } from "../interfaces/users";
import { useMemo } from "react";
import { hasNoPermission } from "../utils/helpers/userHelpers";

function ChecklistPage() {
  const { user } = useSelector((state: AuthenticationState) => state);
  const hasPermission = useMemo(
    () => hasNoPermission("checklist", user),
    [user]
  );

  return (
    <>
      {hasPermission ? (
        <Board title="Checklist" type="checklist" />
      ) : (
        <>
          <div>
            O seu usuário não possui permissão para acessar este conteúdo
          </div>
        </>
      )}
    </>
  );
}

export default ChecklistPage;
