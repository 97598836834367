export interface ClientViewModel {
  name: string;
  taxId: string;
  email?: string;
  phoneNumber?: string;
  alternativePhoneNumber?: string;
  ticket?: TicketViewModel;
  address: {
    street: String;
    add: String;
    neighbor: String;
    city: String;
    state: String;
    zipCode: String;
  };
}

export enum ReferenceType {
  USER = "USER",
  MODULE = "MODULE",
  AGENDA = "AGENDA",
  EVENT = "EVENT",
  TICKET = "TICKET",
  QUESTIONNAIRE = "QUESTIONNAIRE",
  ATTACHMENTS = "ATTACHMENTS",
  INITIAL_PROJECT = "INITIAL_PROJECT",
  FINAL_PROJECT = "FINAL_PROJECT",
  MEASURING = "MEASURING",
  OTHER = "OTHER",
}

export interface FormattedLog {
  userId: number;
  referenceId: number;
  referenceType: ReferenceType;
  action: String;
  createdAt: Date;
  ticketId: number;
}

export interface FormattedComment {
  userId: number;
  content: String;
  createdAt: Date;
  ticketId: number;
}

export interface FormattedEvent {
  agendaId: number;
  start: Date;
  end: Date;
  createdAt: Date;
  ticket: TicketViewModel;
}

export interface TicketViewModel {
  id: number;
  status: number;
  describedStatus: string;
  description: string;
  seller: string;
  clientId: number;
  events: FormattedEvent[];
  history: FormattedLog[];
  comments: FormattedComment[];
  client: ClientViewModel;
  saleId: number;
}
