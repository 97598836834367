import { Button, Popconfirm, Steps } from "antd";
import {
  changeTicketStatus,
  measuringStatusTitles,
  checkListStatusTitles,
  measuringTicketApprove,
  measuringTicketReject,
} from "../../../utils";
import { useQueries } from "react-query";
import { api } from "../../../config";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../interfaces/users";
import { CalendarAppointment } from "../../Checklist/TicketModal/CalendarAppointment";
import {
  ModulePermission,
  hasMinimumPermissions,
  isSharedComponentDisabled,
  userHasPermission,
} from "../../../utils/helpers/userHelpers";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

interface SidePanelInterface {
  status: number;
  ticketId: number;
  type: "checklist" | "measuring";
  ownerId?: string;
}

export const SidePanel = ({
  status,
  ticketId,
  type,
  ownerId,
}: SidePanelInterface) => {
  const { token, user } = useSelector((state: AuthenticationState) => state);
  const [{ data: ticket, refetch }, { refetch: refetchTickets }] = useQueries([
    {
      queryFn: () => api(token).get(`ticket/${type}/${ticketId}`),
      queryKey: [type === "measuring" ? `tickets/${type}` : "tickets"],
      refetchOnMount: false,
    },
    {
      queryFn: () => api(token).get(`tickets/${type}/board`),
      queryKey: [`tickets/${type}/board`],
      refetchOnMount: false,
    },
  ]);
  const stepsTitles =
    type === "measuring" ? measuringStatusTitles : checkListStatusTitles;
  const renderStatus = () => {
    if (status === 5) return "error";
    if (status === 4) return "finish";
    if (status === 6) return "error";
    return "process";
  };

  const handleRefetch = () => {
    refetch();
    refetchTickets();
  };

  const handleTicketStatus = async (status: number, ticketId: number) => {
    await changeTicketStatus(status, ticketId, token, type);
    handleRefetch();
  };

  const handleApprove = async () => {
    await measuringTicketApprove(token, ticketId);
    refetch();
    refetchTickets();
  };

  const handleReject = async () => {
    await measuringTicketReject(token, ticketId);
    refetch();
    refetchTickets();
  };

  const showStatusUi = (status: number, step: number) => {
    if (status === step)
      return (
        <div className="float-right mr-14">
          {type === "measuring" && status === 3 ? (
            <>
              <div className="flex gap-2">
                <Popconfirm
                  placement="topLeft"
                  title={"Rejeitar Ticket"}
                  disabled={!hasMinimumPermissions("measuring", user)}
                  description={`O ticket será rejeitado e retornará para a etapa anterior, confirma?`}
                  onConfirm={handleReject}
                  okText="Sim"
                  cancelText="Cancelar"
                >
                  <Button
                    disabled={!hasMinimumPermissions("measuring", user)}
                    danger
                    type="primary"
                    size="small"
                  >
                    <CloseCircleOutlined />
                  </Button>
                </Popconfirm>
                <Popconfirm
                  placement="topLeft"
                  title={"Aprovar relatório Ticket"}
                  disabled={!hasMinimumPermissions("measuring", user)}
                  description={`O ticket será aprovado e passará para a próxima etapa, confirma?`}
                  onConfirm={handleApprove}
                  okText="Sim"
                  cancelText="Cancelar"
                >
                  <Button
                    disabled={!hasMinimumPermissions("measuring", user)}
                    type="primary"
                    size="small"
                  >
                    <CheckCircleOutlined />
                  </Button>
                </Popconfirm>
              </div>
            </>
          ) : (
            <Popconfirm
              disabled={
                (type === "measuring" && status === 1 && !Boolean(ownerId)) ||
                (type === "measuring" &&
                  status === 2 &&
                  !userHasPermission(user, {
                    measuring: ModulePermission.ADMIN,
                  })) ||
                isSharedComponentDisabled("checklist", user)
              }
              placement="topLeft"
              title={"Atualização do Ticket"}
              description={`O ticket será enviado para próxima etapa. Confirma?`}
              onConfirm={() => handleTicketStatus(status, ticketId)}
              okText="Sim"
              cancelText="Cancelar"
            >
              <Button
                disabled={
                  (type === "measuring" && status === 1 && !Boolean(ownerId)) ||
                  (type === "measuring" &&
                    status === 2 &&
                    !userHasPermission(user, {
                      measuring: ModulePermission.ADMIN,
                    })) ||
                  isSharedComponentDisabled("checklist", user)
                }
                size="small"
              >
                Próxima Etapa
              </Button>
            </Popconfirm>
          )}
        </div>
      );

    return status > step ? "Finalizado" : "";
  };

  const renderFirstStatusDescription = () => {
    if (type === "measuring") {
      return showStatusUi(status, 1);
    }
    return status === 1 ? (
      <CalendarAppointment
        ticket={ticket}
        id={ticketId}
        refetch={handleRefetch}
      />
    ) : (
      "Finalizado"
    );
  };

  return (
    <Steps
      direction="vertical"
      className="float-left"
      style={{ height: 500 }}
      current={status - 1}
      status={renderStatus()}
      items={[
        {
          title: stepsTitles[1],
          description: renderFirstStatusDescription(),
        },
        {
          title: stepsTitles[2],
          description: showStatusUi(status, 2),
        },
        {
          title: stepsTitles[3],
          description: showStatusUi(status, 3),
        },
        {
          title: stepsTitles[4],
          description: status === 4 ? "Ticket concluído." : "",
        },
        {
          title: stepsTitles[5],
          description: status === 5 ? "Ticket em espera no momento." : "",
        },
        {
          title: stepsTitles[6],
          description: status === 6 ? "Ticket em espera no momento." : "",
        },
      ].filter(
        (_, i) => (type === "checklist" && i < 5) || type === "measuring"
      )}
    />
  );
};
