import { toast } from "react-toastify";
import { api } from "../../config";
import { ReferenceType } from "../../interfaces";

export const legacyDownload = async (
  token: string,
  id: number,
  originalFileName: string
) => {
  try {
    const data = await api(token).get(`attachment/download-legacy/${id}`);
    const fileBuffer = new Uint8Array(data.data);
    const blob = new Blob([fileBuffer]);
    const link = document.createElement("a");
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = originalFileName;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    return data;
  } catch (error) {
    toast.error("Ocorreu um erro ao realizar o download do anexo");
  }
};

export const deleteFile = async (id: Number, token: string) => {
  await api(token).delete(`/attachment/${id}`);
};

export const uploadSingleFile = async (
  file: File,
  ticketId: string,
  referenceType: ReferenceType,
  token: string,
  referenceId?: string,
  fieldReportId?: string
) => {
  const form = new FormData();
  form.append("file", file);
  form.append("referenceType", referenceType);
  form.append("referenceId", referenceId ?? "0");
  if (ticketId) {
    form.append("ticketId", ticketId);
  }
  if (fieldReportId) {
    form.append("fieldReportId", fieldReportId);
  }
  try {
    const uploadedFile = await api(token).post("/attachments", form, {
      "Content-Type": "multipart/form-data",
    });
    return uploadedFile;
  } catch (error) {
    toast.error("Ocorreu um erro ao realizar o upload");
  }
};

export const downloadFile = async (id: Number, token: string) => {
  const fileInfo = await api(token).get(`attachment/download/${id}`);
  if (fileInfo.url) {
    return window.open(fileInfo.url);
  }
  const fileBuffer = new Uint8Array(fileInfo.file.data);
  const blob = new Blob([fileBuffer], { type: fileInfo.mimeType });
  const link = document.createElement("a");
  const blobUrl = URL.createObjectURL(blob);
  link.href = blobUrl;
  link.download = fileInfo.originalFileName;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
  document.body.removeChild(link);
};
