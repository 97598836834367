import { Form, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { FiltersForm } from "./FiltersForm";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { UseQueryResult, useQueries } from "react-query";
import { api } from "../../config";
import { TableData } from "./TableData";
import { useState } from "react";
import { getReport } from "../../utils/helpers/reportHelpers";

export const Reports = () => {
  const [form] = Form.useForm();
  const { token } = useSelector((state: AuthenticationState) => state);
  const [results, setResults] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [{ data: usersData, isFetching: usersIsFetching }] = useQueries<
    UseQueryResult[]
  >([{ queryKey: "users", queryFn: () => api(token).get("/users") }]);

  const formatDates = (date: any) => {
    if (date) {
      return {
        start: date[0].toISOString(),
        end: date[1].toISOString(),
      };
    }
    return undefined;
  };

  const onFinish = async (values: any) => {
    if (values.dueDate) {
      values.dueDate = formatDates(values.dueDate);
    } else {
      delete values.dueDate;
    }
    if (values.createdAt) {
      values.createdAt = formatDates(values.createdAt);
    } else {
      delete values.createdAt;
    }
    if (values.endDate) {
      values.endDate = formatDates(values.endDate);
    } else {
      delete values.endDate;
    }
    if (values.filledAt && values.type === "measuring") {
      values.filledAt = formatDates(values.filledAt);
    } else {
      delete values.filledAt;
    }
    setLoading(true);
    const tickets = await getReport(token, values);
    setResults(tickets);
    setLoading(false);
  };

  return (
    <Layout>
      <Content>
        <FiltersForm
          userList={{
            usersData,
            usersIsFetching,
          }}
          formDetails={{
            form,
            onFinish,
          }}
        />
        <TableData loading={loading} searchForm={form} data={results} />
      </Content>
    </Layout>
  );
};
