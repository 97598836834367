import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import { api } from "../../config";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { toast } from "react-toastify";

const AddTicketModal = ({ refetch }: any) => {
  const { token } = useSelector((state: AuthenticationState) => state);
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOk = async (values: any) => {
    setLoading(true);
    try {
      await api(token).post("/ticket/measuring", {
        title: values.title,
        client: {
          name: values.name,
          taxId: values.taxId ?? "",
          phoneNumber: values.phoneNumber,
          alternativePhoneNumber: values.alternativePhoneNumber ?? "",
          email: values.email ?? "",
        },
        address: {
          street: values.street,
          neighbor: values.neighbor ?? "",
          city: values.city ?? "",
          state: values.state ?? "",
          add: values.add ?? "",
          zipCode: values.zipCode ?? "",
        },
      });
      refetch();
      setShow(false);
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      setLoading(false);
      toast.error(
        "Ocorreu um erro ao criar o Ticket, por favor, tente novamente mais tarde"
      );
    }
  };

  const handleClose = () => {
    form.resetFields();
    setShow(false);
  };

  return (
    <>
      <Button
        shape="circle"
        type="link"
        onClick={() => setShow(!show)}
        icon={<PlusCircleOutlined />}
      />
      <Modal
        title="Adicionar Ticket de Medição"
        open={show}
        onCancel={handleClose}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          style={{ maxWidth: 700, maxHeight: 500, overflowY: "auto" }}
          onFinish={handleOk}
        >
          <b>Ticket</b>
          <Form.Item name="title" label="Título" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <b>Cliente</b>
          <Form.Item name="name" label="Nome" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Telefone"
            rules={[
              {
                required: true,
                pattern: new RegExp(
                  /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})?(\d{4}))$/g
                ),
                message: "(XX)XXXXXXXXX",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="alternativePhoneNumber"
            label="Tel. Alt."
            rules={[
              {
                required: false,
                pattern: new RegExp(
                  /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})?(\d{4}))$/g
                ),
                message: "(XX)XXXXXXXXX",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="taxId" label="CPF">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>
          <b>Endereço</b>
          <Form.Item name="street" label="Rua" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="neighbor"
            label="Bairro"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="add" label="Compl.">
            <Input />
          </Form.Item>
          <Form.Item name="city" label="Cidade" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="state" label="Estado">
            <Input />
          </Form.Item>
          <Form.Item name="zipCode" label="CEP">
            <Input />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading ? true : false}
          >
            Enviar
          </Button>
        </Form>
      </Modal>
    </>
  );
};
export default AddTicketModal;
