import { Button, Tooltip } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { getInitials } from "../../utils";

function Filter({ data, setFilter }: any) {
  const titles = Array.from(new Set(data.map((item: any) => item.title)));

  const titlesColors = titles.map((title: any) => {
    const color = data.find((item: any) => item.title === title);
    return { color: color.color, title: color.title };
  });

  return (
    <>
      <div className="flex	items-center">
        <Tooltip title="Limpar filtros">
          <Button
            type="default"
            shape="circle"
            size="small"
            disabled={titlesColors.length > 1}
            icon={<CloseCircleOutlined />}
            onClick={() => setFilter({ title: {} })}
          />
        </Tooltip>

        {titlesColors.map((item) => (
          <Tooltip key={item.title} title={item.title}>
            <Button
              size="small"
              style={{
                backgroundColor: item.color,
                color: "white",
                margin: 10,
              }}
              onClick={() => setFilter({ title: item.title })}
            >
              {getInitials(item.title)}
            </Button>
          </Tooltip>
        ))}
      </div>
    </>
  );
}
export default Filter;
