import { Card, Layout } from "antd";
import { Content } from "antd/es/layout/layout";

import { PasswordForm } from "./PasswordForm";
import { UserInformationForm } from "./UserInformationForm";

export const Profile = () => {
  return (
    <Layout>
      <Content>
        <Card title="Meu Perfil">
          <div className="flex w-full justify-evenly">
            <UserInformationForm shouldDisplayFooter />
            <PasswordForm shouldDisplayFooter />
          </div>
        </Card>
      </Content>
    </Layout>
  );
};
