import { AutoComplete } from "antd";

interface SearchBarInterface {
  placeholder: string;
  options: Array<any>;
  onChangeFunction: any;
}

export const SearchBar = ({
  placeholder,
  options,
  onChangeFunction,
}: SearchBarInterface) => {
  return (
    <AutoComplete
      className="w-96 ml-2"
      allowClear
      options={options}
      onSelect={onChangeFunction}
      placeholder={placeholder}
      filterOption={(inputValue, option) => {
        const regex = new RegExp(inputValue, "i");
        return (
          option.title.match(regex) ||
          option.externalId.match(regex) ||
          option.saleId.match(regex) ||
          option.id.match(regex)
        );
      }}
    />
  );
};
