import { Typography, Badge, Progress, Skeleton } from "antd";
import { SidePanel } from "../../Shared/Components/SidePanel";
import NavigationTabs from "./Tabs";
import { renderPercentage, checkListStatusTitles } from "../../../utils";
import { api } from "../../../config";
import { useQuery } from "react-query";
import { useEffect } from "react";

import TicketContext from "../../Shared/contexts/TicketContext";
import ButtonMore from "./ButtonMore";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../interfaces/users";

const { Title } = Typography;

function ModalContainer({ ticketId }: any) {
  const { token } = useSelector((state: AuthenticationState) => state);
  const { isFetching, data, isError, refetch }: any = useQuery(
    ["tickets"],
    () => api(token).get(`ticket/checklist/${ticketId}`)
  );
  useEffect(() => {
    if (!isError) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, ticketId, data]);

  if (isFetching) return <Skeleton active />;
  if (isError)
    return (
      <>
        <Title className=" border-b-2  border-slate-100 pb-1 ">
          Erro durante o processo de buscar ticket
        </Title>
      </>
    );

  console.log(data);
  return (
    <div>
      <Badge.Ribbon
        className="px-8 mt-5 mx-2"
        text={
          checkListStatusTitles[
            data.fieldReport?.status === "MEASURING_WIP" ? 6 : data?.status ?? 1
          ]
        }
        color={
          data?.status === 5 ||
          data?.status === 6 ||
          data.fieldReport?.status === "MEASURING_WIP"
            ? "red"
            : "blue"
        }
      />
      <Title className=" border-b-2 border-slate-100 pb-1 ">
        {data.title}
        <div className=" z-10 w-40 pl-40 h-0 justify-center  top-6 left-3/4  grid relative">
          <ButtonMore ticketId={data.id} />
        </div>
      </Title>
      <div className="flex">
        <div className="w-1/5">
          <SidePanel
            status={data?.status}
            ticketId={data.id}
            type="checklist"
          />
        </div>
        <div className="w-4/5  overflow-hidden">
          <TicketContext ticket={data}>
            <NavigationTabs />
          </TicketContext>
        </div>
      </div>
      <div className="w-11/12 absolute bottom-3">
        <div className="w-full flex justify-center items-center">
          <p className="mr-5">Progresso:</p>
          <Progress
            className="mt-2"
            status={data?.status === 5 ? "exception" : "active"}
            percent={renderPercentage(data?.status)}
          />
        </div>
      </div>
    </div>
  );
}

export default ModalContainer;
